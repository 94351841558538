.wrap * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wrap {
    max-width: 1200px;
    min-height: 100vh;
    padding: 40px;
    font-family: "Noto Sans KR";
    font-weight: 400;
    line-height: 1;
    color: #3A3A3A;
}

/* 제목 영역 */

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.btn_svg {
    width: 16px;
    fill: #333;
    margin-right: 4px;
    margin-bottom: 2px;
}

.btn_txt {
    font-size: 13px;
    margin-bottom: 1px;
}

.title_text {
    padding-right: 12px;
}

.title_text h2 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
}

.title_text h3 {
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
    word-break: keep-all;
}

/* 목록 영역 */

.content {
    width: 100%;
    margin-top: 40px;
    padding: 32px 24px;
    border-radius: 8px;
    background-color: #FFF;
    box-shadow: 0px 0px 8px 0px #0000001A;
}

.content_title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.content_title button {
    flex-shrink: 0;
}

.content_title > button:last-of-type {
    margin-left: 16px;
}

.search_bar {
    max-width: 316px;
    width: 100%;
    margin-right: 6px;
    position: relative;
}

.search_bar input {
    width: 100%;
    height: 36px;
    font-size: 14px;
    padding: 7px 16px;
    padding-right: 40px;
    margin-right: 6px;
    transition: 0.1s;
}

.search_bar input::placeholder {
    font-size: 14px;
    color: #9FA4AA;
}

.search_bar button {
    width: 20px;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: default;
}

.search_bar button svg {
    width: 100%;
}

/* 목록 */

.list_wrap {
    max-width: 100%;
    /* width: 992px; */
    overflow-x: auto;
}

.list {
    width: max-content;
    margin-top: 20px;
}

.list thead tr {
    height: 36px;
}

.list thead tr th {
    vertical-align: middle;
    text-align: center;
    border-right: 2px solid #FFF;
}

.list thead tr th:first-of-type { width: 46px; }
.list thead tr th:nth-of-type(2) { width: 50px; }
.list thead tr th:nth-of-type(3) { width: 300px; }
.list thead tr th:nth-of-type(4) { width: 152px; }
.list thead tr th:nth-of-type(5) { width: 152px; }
.list thead tr th:nth-of-type(6) { width: 160px; }
.list thead tr th:nth-of-type(7) { width: 64px; }
.list thead tr th:nth-of-type(8) { width: 70px; }
.list thead tr th:nth-of-type(9) { width: 70px; }

.list_sort {
    display: flex;
    justify-content: center;
    align-items: center;
}

.list_sort > div {
    margin-right: 4px;
}

.list_sort > label {
    cursor: pointer;
}

.list_sort > label svg {
    fill: #3A3A3A;
}

.list_sort > label:hover svg {
    fill: #00E2E6;
}

.list_sort > input:checked + label svg {
    fill: #00E2E6;
}

/* 목록이 없을 때 */

.list tbody .no_list td {
    width: 100%;
    padding: 120px 0;
    font-size: 15px;
    line-height: 21px;
    color: #878B90;
}

.list tbody .no_list td button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 24px;
}

/* 목록이 있을 때 */

.list_row {
    height: 72px;
    border-bottom: 1px solid #EEE;
}

.list_row:hover {
    background-color: #F7F7F7;
}

.list_row td {
    vertical-align: middle;
    cursor: pointer;
}

.list_row .status button {
    margin: 0 auto;
    margin-top: 4px;
}

/* 디자인 보기 팝업 */

.design_popup_wrap {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000073;
}

.design_popup {
    width: 896px;
    max-height: calc(100vh - 40px);
    padding: 40px 0;
    padding-bottom: 28px;
    border-radius: 16px;
    background-color: #FFF;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.design_popup > button {
    position: absolute;
    right: 20px;
    top: 16px;
}

.design_popup h2 {
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 10px;
    padding: 0 20px;
    padding-left: 24px;
    flex-shrink: 0;
}

.design_popup h3 {
    font-size: 13px;
    line-height: 1;
    font-weight: 400;
    flex-shrink: 0;
    padding: 0 20px;
    padding-left: 24px;
}

.design_popup .no_design_list {
    width: 100%;
    padding: 120px 0;
    padding-top: 106px;
    font-size: 15px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.design_popup_list_wrap {
    width: 100%;
    max-height: 666px;
    overflow-y: auto;
    margin-top: 12px;
    padding: 12px 20px;
    padding-top: 0;
    display: flex;
    flex-flow: row wrap;
    gap: 0 12px;
}

.design_popup_list_row {
    width: calc(20% - 10px);
    padding: 12px;
    padding-bottom: 37px;
    margin-top: 12px;
    border-radius: 12px;
    box-shadow: 0px 3px 10px 0px #0000001F;
    position: relative;
}

.design_popup_list_dummy_row {
    width: 20%;
}

.design_popup_list_row .banner_img {
    width: 100%;
    height: 136px;
    background-color: #D9D9D9;
}

.design_popup_list_row .banner_img img {
    width: 100%;
    height: 136px;
    object-fit: cover;
}
.design_popup_list_row .banner_type{
    display: flex;
}
.design_popup_list_row .banner_type > div {
    width: 40px;
    height: 20px;
    border-radius: 20px;
    font-size: 12px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}
.design_popup_list_row .banner_type .banner {
    background-color: #AEF3F2;
    margin-right: 4px;
}
.design_popup_list_row .banner_type .popup {
    background-color: #B9DDFF;
}

.design_popup_list_row .banner_title {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: keep-all;
    overflow: hidden;
    font-size: 15px;
    line-height: 21px;
    margin-top: 12px;
}

.design_popup_list_row .banner_date {
    font-size: 13px;
    line-height: 13px;
    color: #9FA4AA;
    width: 100%;
    padding-left: 12px;
    position: absolute;
    left: 0;
    bottom: 16px;
}

/* 반응형 - 모바일 */
@media screen and (max-width: 1024px) {
    .design_popup_list_row {
        width: calc(25% - 9px);
    }
}

@media screen and (max-width: 800px) {
    .wrap {
        padding: 20px;
    }
    .design_popup_wrap {
        padding: 20px;
    }
    .design_popup_list_row {
        width: calc(33.3% - 8px);
    }
}

@media screen and (max-width: 640px) {
    .design_popup_list_row {
        width: calc(50% - 6px);
    }
    .content_title > button:last-of-type {
        margin-left: 6px;
    }
}

@media screen and (max-width: 480px) {
    .design_popup_list_row {
        width: 100%;
    }
}