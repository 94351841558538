:global .create_banner_preview .common_popup_frame_v2_wrap{
    justify-content: center;
}
:global .create_banner_preview .slick-prev{
    background-image: url("../../../resources/img/arrow_before_dark.svg") !important;
    left: -36px;
}
:global .create_banner_preview .slick-prev.slick-disabled{
    /* background-image: url("../../../resources/img/arrow_before.svg") !important; */
}
:global .create_banner_preview .slick-next{
    background-image: url("../../../resources/img/arrow_after_dark.svg") !important;
    right: -36px;
}
:global .create_banner_preview .slick-next.slick-disabled{
    /* background-image: url("../../../resources/img/arrow_after.svg") !important; */
}
:global .create_banner_preview .slick-arrow{
    background-size: contain !important;
    background-repeat: no-repeat !important;
    width: 24px;
    height: 24px;
}
:global .create_banner_preview .slick-arrow:before{
    content : none !important;
}
@media (max-width:800px) {
    :global .create_banner_preview .slick-prev{
        left: -30px;
    }
    :global .create_banner_preview .slick-next{
        right: -32px;
    }
}

.popup_title{
    font-family: "Noto Sans KR";
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 8px;
}
.popup_content{
    font-family: "Noto Sans KR";
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 12px;
}
.popup_preview_area{
    margin: 32px 36px 0px 36px;
    padding: 24px 48px;
    background: #EEEEEE;
    border-radius: 8px;
}
.preview_title{
    font-family: "Noto Sans KR";
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    margin-bottom: 24px;
}
.preview_ratio_title{
    font-family: "Noto Sans KR";
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    margin-bottom: 8px;
    align-self: baseline;
}
.popup_preview{
    display: flex !important;
    justify-content: space-between;
}
.banner_slide_area{
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
}
.btn_area{
    width: 100%;
    margin: 32px 0px 28px 0px;
    display: flex;
    justify-content: center;
}
.robo_img_pc_banner, .robo_img_mobile_banner{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hidden_area{
    display: none;
}

@media (max-width:800px) {
    .popup_preview_area{
        padding: 24px 40px;
        margin: 32px 4px 0px 4px;
    }
    .popup_preview{
        display: block !important;
    }
    .popup_content{
        margin-top: 10px;
    }
    .robo_img_pc_banner{
        width: 100% !important;
    }
    .robo_img_mobile_banner{
        width: 100% !important;
        margin-top: 24px;
    }
}