.wrap{
    padding: 40px;
    background: #F4F7F9;
    min-height: calc(100vh - 71px);
}
.page_title{
    font-family: "Noto Sans KR";
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color:#202020;
    margin-bottom: 40px;
}
.box{
    box-shadow: 0px 0px 8px 0px #0000001A;
    background: #FFFFFF;
    padding: 40px;
    border-radius: 8px;
    margin-bottom: 24px;
}
.box:last-of-type{
    margin-bottom: 40px;
    padding-bottom: 36px;
}
.title{
    font-family: "Noto Sans KR";
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    color:#3A3A3A;
    margin-bottom: 8px;
}
.content{
    font-family: "Noto Sans KR";
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    margin-bottom: 36px;
    color:#3A3A3A;
}
.select_area{
    margin-bottom: 36px;
}
.select_title{
    font-family: "Noto Sans KR";
    font-size: 15px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    color: #3A3A3A;
    margin-bottom: 8px;
}
.select{
    max-width: 960px;
    width: 100%;
}
.select_error_msg{
    margin-left: 16px;
    margin-top: 4px;
    font-family: 'Noto Sans KR';
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #FF085E;
}
.button_area{
    display: flex;
}
.button_area > button:last-of-type {
    margin-left: 12px;
}
.warning{
    margin-top: 12px;
}

@media (max-width: 800px) {
    :global .targetViewSetting .react-select.referrer_type [class*='MenuList']{
        max-height: 280px;
    }
    :global .targetViewSetting .react-select.category_type [class*='MenuList']{
        max-height: 180px;
    }
}