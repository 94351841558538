[contenteditable=true]:empty:before{
    content: attr(placeholder);
    display: block; /* For Firefox */
    color: #acacac;
}
.container_text_editor {
    display: flex;
    flex-direction: column;
    margin: 30px auto 0 34px;
}
.wrap_text_editor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #DBE7F2;
    width: 100%;
    max-width: 800px;
    padding: 16px 22px;
    border-radius: 13px 13px 0 0;
}
.line_text_editor {
    float: left;
    margin-top: 3px;
}

@media (max-width: 1145px) {
    .line_text_editor {
        display: none;
    }
}

.select_box_text_editor{
    width: 100%;
    height: 100%;
}
.font_family_text_editor, .font_size_text_editor, .font_color_text_editor {
    display: inline-flex;
    float: left;
    background: #FFF;
    height: 38px;
    margin: 5px;
    border-radius: 6px;
}
.font_family_text_editor {
    position: relative;
    width: 272px;
    min-width: 100px;
    border-radius: 8px;
    border: 1px solid #D1D1D1;
    cursor: pointer;
}
.txt_font_family_text_editor, .txt_font_size_text_editor {
    padding: 7px 13px;
}
.select_list_text_editor {
    display: block;
    position: absolute;
    top: 42px;
    width: 100%;
    background: #FFF;
    z-index: 9991;
    box-shadow: 0 0 8px 6px rgba(0,0,0,.09);
    border-radius: 13px;
    line-height: 30px;
    text-align: left;
    font-size: 15px;
    overflow: auto;
    cursor: pointer;
}
.select_list_text_editor_mobile {
    position: fixed;
    top: 1000vh;
    left: 0;
    bottom: -15px;
    width: 100%;
    padding: 40px 0 30px 30px;
    background: #FFF;
    z-index: 10000;
    box-shadow: 0 0 8px 6px rgba(0,0,0,.09);
    border-radius: 13px;
    line-height: 39px;
    text-align: left;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.8s ease-in-out;
    /*transition: cubic-bezier(.57,.02,1,.45);*/
}
.select_list_text_editor_mobile ul {
    width: 100%;
    height: 100%;
    overflow: auto;
}
.btn_close_select_list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.select_list_text_editor li {
    padding: 2px 20px;
}
.select_list_text_editor li:hover {
    background: #EEE;
}
.select_box_arrow {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px 8px;
}
.select_list_font_family {
    height: 500px;
}
.select_list_font_family_mobile {
    /*height: 70vh;*/
}
.select_list_font_size {
    height: 238px;
}
.select_list_font_size_mobile {
    /*height: 350px;*/
}
.font_size_text_editor {
    position: relative;
    width: 155px;
    min-width: 100px;
    border-radius: 8px;
    border: 1px solid #D1D1D1;
    cursor: pointer;
}
.input_font_color_text_editor, .input_font_back_color_text_editor {
    margin: 5px;
    width: 116px;
    height: 28px;
    font-size: 16px;
    cursor: default;
    border: 1px solid #D1D1D1;
    border-radius: 6px;
}
.input_font_color_text_editor[readonly], .input_font_back_color_text_editor[readonly]{
    background-color: #FFF !important;
}
.input_font_color_text_editor:disabled, .input_font_back_color_text_editor:disabled {
    color: #555;
}
.wrap_font_color_text_editor {
    display: inline-flex;
}
.box_font_color_text_editor, .box_font_back_color_text_editor {
    width: 130px;
    height: 28px;
    border: 1px solid #ACACAC;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 5px;
    cursor: pointer;
}
.icon_font_color_text_editor, .icon_font_back_color_text_editor {
    margin: auto 12px 12px 6px;
    cursor: pointer;
}
.wrap_sketchpicker {
    position: relative;
}
.font_color_sketchpicker {
    display: none;
    position: absolute;
    top: -320px;
    right: -13px;
    z-index: 999;
}
.font_back_color_sketchpicker {
    display: none;
    position: absolute;
    top: -320px;
    right: -13px;
    z-index: 999;
}
.bg_text_editor {
    display: inline-flex;
    float: left;
    background: #FFF;
    height: 38px;
    margin: 5px;
    border-radius: 6px;
    padding-right: 5px;
}
.icon_text_editor {
    display: inline-block;
    padding: 0 3px;
    width: 33px;
    height: 33px;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
    margin: 3px 0 3px 5px;
    z-index: 99;
    background: #FFF;
}

/* 에디터 하단 문구 */
.notice_text_editor {
    margin-top: 3px;
    margin-bottom: 0;
    font-size: 12px;
    color: #ACACAC;
    text-align: right;
    max-width: 800px;
}

@media (max-width: 800px) {
    .notice_text_editor {
        text-align: left;
    }
}

@media (max-width: 1145px) {
    .input_font_color_text_editor, .input_font_back_color_text_editor {
        width: 80px;
    }
    .box_font_color_text_editor, .box_font_back_color_text_editor {
        width: 30px;
    }
}

@media (max-width: 660px) {
    .font_family_text_editor, .font_size_text_editor {
        width: 100%;
    }
}

/* 자간 선택박스 */
.select_box_letter_spacing, .select_box_line_height {
    position: absolute;
    border: none;
    width: 53px;
    height: 33px;
    margin: 3px;
}

.bg_select_box {
    width: 65px;
}

.select_box_letter_spacing:hover, .select_box_letter_spacing:active, .select_box_letter_spacing:focus,
.select_box_line_height:hover, .select_box_line_height:active, .select_box_line_height:focus{
    border: none !important;
}

.text_editor_title {
    position: relative;
    z-index: 999;
}

/* 초기화 버튼 */
.txt_removeFormat {
    margin: 7px auto auto auto;
    width: 50px;
    cursor: pointer;
}

/* 이모지 버튼 */
.wrap_emoji_text_editor{
    position: relative;
}
.emoji_text_editor{
    display: inline-flex;
    float: left;
    background: #FFF;
    width: 50px;
    height: 38px;
    margin: 5px;
    border-radius: 6px;
}
.emoji_picker {
    font-size: 25px;
    margin: 0 auto;
    padding-right: 0 !important;
    cursor: pointer;
}
em-emoji-picker, .EmojiPickerReact {
    display: none;
    z-index: 9991;
    position: absolute !important;
    top: 50px;
    right: -28px;
    overflow: auto;
}

@media (max-width: 879px) {
    em-emoji-picker, .EmojiPickerReact {
        right: -296px !important;
    }
}

@media (max-width: 800px) {
    em-emoji-picker, .EmojiPickerReact {
        right: -40px !important;
    }
}

@media (max-width: 433px) {
    em-emoji-picker, .EmojiPickerReact {
        left: -100px !important;
    }
}

/* 리스트, 순서리스트 버튼 */
.text_editor_title ul li {
    list-style: disc;
}
.text_editor_title ol li {
    list-style: decimal;
}
.text_editor_title ul, .text_editor_title ol {
    padding-inline-start: 1em !important;
}

/* 이미지 첨부 버튼 */
.img_text_editor {
    width: 40px;
    text-align: center;
}
.wrap_img_attach_editor {
    position: relative;
    width: 100%;
    max-width: 800px;
}
.img_attach_editor {
    display: none;
    z-index: 9991;
    background: #FFF;
    width: 190px;
    height: 88px;
    position: absolute;
    top: -135px;
    left: -83px;
    box-shadow: 0 0 8px 6px rgba(0,0,0,.09);
    border-radius: 13px;
    padding: 15px 20px;
    line-height: 30px;
    text-align: left;
    font-size: 15px;
}
.img_upload_editor, .enter_img_url_editor {
    cursor: pointer;
}

.text_editor_title img {
    width: 100px;
    vertical-align: middle;
}

.text_editor_title font[size='1']{
    font-size: 0.8em;
}
.text_editor_title font[size='2']{
    font-size: 1.0em;
}
.text_editor_title font[size='3']{
    font-size: 1.2em;
}
.text_editor_title font[size='4']{
    font-size: 1.4em;
}
.text_editor_title font[size='5']{
    font-size: 1.6em;
}
.text_editor_title font[size='6']{
    font-size: 1.8em;
}
.text_editor_title font[size='7']{
    font-size: 2.0em;
}