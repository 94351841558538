.roboimage_result * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.roboimage_result {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 128px 40px 40px 40px;
    box-sizing: border-box;
    color: #202020;
}
.roboimage_result h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
}
.roboimage_result h2 .mobile_br {
    display: none;
}
.content_area{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
    max-width: 1400px;
    width: 100%;
}
.content_title{
    font-family: "Noto Sans KR";
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    margin-bottom: 10px;
}
.roboimage_result button {
    width: 217px;
    height: 46px;
    font-size: 15px;
    line-height: 22px;
    margin-top: 56px;
    font-weight: 400;
    letter-spacing: 0em;
}
@media screen and (max-width: 720px) {
    .roboimage_result {
        padding: 32px 16px 52px 16px;
    }
    .roboimage_result h2 {
        font-size: 16px;
        line-height: 22.4px;
    }
    .roboimage_result h2 .mobile_br {
        /* display: block; */
    }
    .content_area{
        flex-direction: column;
        width: 100% !important;
        height: auto !important;
        margin-top: 32px;
    }
    .content_title{
        font-size: 14px;
        line-height: 14px;
    }
    .robo_img_area{
        margin-top: 32px;
    }
    .banner{
        width: 100% !important;
    }
    .robo_img {
        width: 100%;
    }
    .roboimage_result button {
        /* max-width: 328px; */
        width: 100%;
        margin-top: 40px;
    }
}