.wrap {
    width: 100%;
    min-height: calc(100vh - 72px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Noto Sans KR", "Roboto", sans-serif;
    font-weight: 400;
    color: #3A3A3A;
    padding-top: 60px;
    padding-bottom: 72px;
}

.wrap > button {
    display: none;
}

.txt_wrap {
    display: flex;
    flex-direction: column;
    margin-right: 76px;
}

.txt_wrap h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 46px;
    margin-bottom: 0;
}

.txt_wrap h3 {
    font-size: 26px;
    font-weight: 500;
    line-height: 42px;
    margin-top: 24px;
    margin-bottom: 48px;
}

.img_wrap {
    display: flex;
    align-items: center;
}

.img_wrap .box:nth-of-type(1) { background-color: #B5F4C7F4; }
.img_wrap .box:nth-of-type(2) { background-color: #82F3A6; }
.img_wrap .box:nth-of-type(3) { background-color: #4FE980; }

.box {
    width: 246px;
    height: 246px;
    border-radius: 13px;
    padding: 24px;
    position: relative;
    box-shadow: 0px 4px 15px 0px #0000000D;
}

.box p {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

.box svg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.img_wrap .box:nth-of-type(1) svg {
    bottom: 53px;
}

.img_wrap .box:nth-of-type(2) svg {
    bottom: 25px;
}

.img_wrap .box:nth-of-type(3) svg {
    bottom: 33px;
}

@media screen and (max-width: 1600px) {
    .wrap {
        flex-direction: column;
    }

    .wrap > button {
        display: block;
        margin-top: 56px;
    }

    .txt_wrap {
        text-align: center;
        align-items: center;
        margin-right: 0;
    }

    .txt_wrap button {
        display: none;
    }

    .txt_wrap h2 {
        font-size: 28px;
        line-height: 1.6;
    }
    
    .txt_wrap h3 {
        font-size: 24px;
        line-height: 1.5;
        margin-top: 20px;
    }
}

@media screen and (max-width: 1080px) {
    .img_wrap {
        flex-direction: column;
        gap: 12px;
    }

    .img_wrap > svg {
        transform: rotate(90deg);
    }

    .txt_wrap h2 {
        font-size: 26px;
    }
    
    .txt_wrap h3 {
        font-size: 22px;
        margin-top: 16px;
    }
}

@media screen and (max-width: 400px) {
    .wrap {
        padding-left: 20px;
        padding-right: 20px;
    }

    .txt_wrap h3 br {
        display: none;
    }
}