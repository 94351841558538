.roboimage_intro * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.roboimage_intro {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: "Noto Sans KR";
    font-weight: 400;
    line-height: 1.33;
    color: #3A3A3A;
    padding-bottom: 40px;
    box-sizing: border-box;
}
.bg {
    width: 100%;
    height: 365px;
    object-fit: cover;
}
.content {
    width: 552px;
    position: relative;
}
.content .character {
    width: 269px;
    position: absolute;
    right: -19px;
    top: -180px;
}
.content h2 {
    position: relative;
    z-index: 2;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: -1.2px;
    word-break: keep-all;
    margin: 0;
}
.content h2 .mobile_br {
    display: none;
}
.content h2 .regular {
    font-size: 42px;
}
.content h2 .bold {
    font-weight: 800;
    color: #00D3E0;
}
.content h2 .underline {
    position: relative;
    font-weight: 800;
}
.en .content h2 {
    font-size: 42px;
    line-height: 1.1;
}
.en .content h2 .underline {
    font-size: 48px;
}
.content h2 .underline::before {
    content: '';
    width: 100%;
    height: 24px;
    background-color: #A0FDFF;
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0;
}
.content h3 {
    font-size: 17px;
    font-weight: 400;
    line-height: 1.5;
    word-break: keep-all;
    margin-top: 26px;
    margin-bottom: 0;
}
.en .content h3 {
    color: #63666a;
}
.content h3 .mobile_br {
    display: none;
}
.textarea_border{
    width: 100%;
    height: 173px;
    border: 1px solid #D1D1D1;
    border-radius: 8px;
    margin-top: 52px;
    padding: 12px 0px 60px 0px;
    display: block;
    position: relative
}
@media (hover: hover) and (pointer: fine) {
    .textarea_border:hover {
        border-color: #515151;
    }
}
.content textarea {
    width: 100%;
    height: 100%;
    font-size: 15px;
    line-height: 1.47;
    word-break: keep-all;
    color: #3A3A3A;
    resize: none;
    border: none;
    padding: 0px 16px;
    /* display: block; */
}
.content textarea::placeholder {
    color: #9FA4AA;
}
.content .textarea_info{
    font-family: "Noto Sans KR";
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color : #9FA4AA;
    margin-left: 16px;
    margin-top: 4px;
}
.textarea_btn {
    font-family: "Noto Sans KR";
    position: absolute !important;
    right: 16px;
    bottom: 19px;
    font-size: 13px;
    border-radius: 50px !important;
    font-weight: 400;
}
.textarea_new_btn img{
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-bottom: 2px;
}
.content .button_wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}
.content .button_wrap button {
    width: 268px;
    height: 46px;
    font-size: 15px;
    font-weight: 400;
    color: #202020;
    border-radius: 46px;
}
.content .button_wrap img {
    width: 18px;
    height: 18px;
    margin-left: 8px;
    margin-bottom: 4px;
}
.promotion {
    width: 100%;
    margin-top: 24px;
}
.regist_btn{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
@media screen and (max-width: 720px) {
    .bg {
        height: 218px;
    }
    .content {
        width: 100%;
        padding: 0 16px;
    }
    .content .character {
        width: 146px;
        right: auto;
        left: 50%;
        top: -121px;
        transform: translateX(-50%);
    }
    .content h2 {
        font-size: 34px;
        line-height: 42px;
        text-align: center;
        margin-top: 12px;
    }
    .en .content h2 {
        font-size: 28px;
    }
    .content h2 .mobile_br {
        display: block;
    }
    .content h2 .regular {
        font-size: 28px;
    }
    .en .content h2 .underline {
        font-size: 34px;
    }
    .en .content h2 .underline::before {
        height: 18px;
    }
    .content h3 {
        font-size: 15px;
        text-align: center;
    }
    .content h3 span:first-of-type {
        display: inline-block;
        margin-bottom: 11px;
    }
    .textarea_border{
        margin-top: 42px;
    }
    .content textarea {
        font-size: 13px;
    }
    .content .textarea_info{
        margin-top: 2px;
        font-size: 12px;
        line-height: 15px;
    }
    .content .button_wrap {
        margin-top: 16px;
    }
    .content .button_wrap button {
        width: calc(50% - 6px);
    }
}
@media screen and (max-width: 450px) {
    .content h3 .mobile_br {
        display: block;
    }
}