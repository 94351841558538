.email_verify_wrap {
    background: #00E2E6;
    display: flex;
    padding: 18px 0px;
}
.email_verify_wrap * {
    box-sizing: border-box;
}
.flexWrap {
    display: flex;
    margin: auto;
    text-align: center;
    justify-content: center;
    flex-direction: column;
}

.content {
    font-size: 18px;
    line-height: 150%;
    margin-top: 16px;
}

.slider_wrap {
    width: 400px;
    height: 400px;
    background: #FFFFFF;
    align-self: center;
    margin-top: 32px;
    border-radius: 12px;
    overflow: hidden;
}

.slider_wrap img {
    width: 100%;
    height: 100%;
}

.btn_wrap {
    display: flex;
    width:  400px;
    height: 46px;
    background: #333333;
    border-radius: 50px;
    margin-top: 32px;
    color: white;
    align-items: center;
    cursor:pointer;
}

.btn_content {
    margin: auto;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
}


