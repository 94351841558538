:global .robo_image_video_popup .common_popup_frame{
    position: fixed;
    justify-items: center;
    align-items: center;
    overflow: hidden;
}

:global .robo_image_video_popup .common_popup_frame_div{
    /* height: 100%; */
    /* max-height: 645px; */
    padding: 40px;
}
:global .robo_image_video_popup .common_popup_frame_title button{
    height: 0px;
}
:global .robo_image_video_popup .common_popup_frame_title img{
    transform: translate(26px, -32px);
}
:global .robo_image_video_popup .display_none{
    z-index: 0;
    opacity: 0;
}
.video{
    width: 100%;
    display: flex;
    justify-content: center;

    position: relative;
    height: 0;
    padding-bottom: 56.2%;
}

.video iframe{
    position: absolute;
    top: 0;
    left: 0;
}
@media screen and (max-width: 720px) {
    :global .robo_image_video_popup .common_popup_frame_div{
        padding: 40px 32px;
    }
    :global .robo_image_video_popup .common_popup_frame_title img{
        transform: translate(20px, -32px);
    }
}
@media screen and (max-height: 730px){
    :global .robo_image_video_popup.has-scroll .close_area .common_popup_frame_div{
        height: 84vh;
        width: 140vh !important;
    }
    .video{
        height: 100%;
    }
}
@media screen and (max-height: 600px){
    :global .robo_image_video_popup.has-scroll .close_area .common_popup_frame_div{
        width: 138vh !important;
    }
}
@media screen and (max-height: 430px) {
    :global .robo_image_video_popup.has-scroll .close_area .common_popup_frame_div{
        width: 130vh !important;
        height: 80vh;
    }
}