/* 공통 */
.homepage_wrap select,option,button{
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}
.homepage_wrap select{
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url('https://robomd.io/images/home/chevron-down.svg') calc(100% - 5px) center no-repeat;
    background-size: 16px;
}
.homepage_wrap select:hover{
    border: 0px !important;
}
.homepage_wrap select:focus {
    border: 0px !important;
}
.homepage_wrap select::-ms-expand{
    display: none;
}
.homepage_wrap a{
    text-decoration: none;
    color: #3a3a3a !important;
    align-items: center;
    display: flex;
}

.homepage_wrap_login {
    position: relative;
    z-index: 10;
}
.homepage_wrap_login select,option,button{
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}
.homepage_wrap_login select{
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url('https://robomd.io/images/home/chevron-down.svg') calc(100% - 5px) center no-repeat;
    background-size: 16px;
}
.homepage_wrap_login select:hover{
    border: 0px !important;
}
.homepage_wrap_login select:focus {
    border: 0px !important;
}
.homepage_wrap_login select::-ms-expand{
    display: none;
}
.homepage_wrap_login a{
    text-decoration: none;
    color: #3a3a3a !important;
    align-items: center;
    display: flex;
}

/* 헤더 */
.hidden{
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    touch-action: none;
}
.homepage_wrap_header{
    width: 100%;
    height: 80px;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    transition-duration: 0.2s;
    padding: 0 !important;
}
.header_wrap{
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

/* 로그인페이지 전용 헤더 스타일링 */
.homepage_wrap_header_login {
    position: relative;
    width: 100%;
    height: 80px;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    /* transition-duration: 0.2s; */
    padding: 0 6% 0 13.66vw !important;
}
.header_wrap_login{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width: 1400px) and (min-width: 1081px) {
    .homepage_wrap_header_login {
        width: 1400px;
        justify-content: flex-start;
        padding: 0 0 0 190px !important; 
    }
    .header_wrap_login{
        width: 1120px;
    }
}
@media screen and (min-width: 2048px) {
    .homepage_wrap_header_login {
        width: 100%;
        position: relative;
        padding: 0 6% 0 13.66vw !important;
    }
    .header_right_login {
        width: 50%;
        position: absolute;
        right: 0;
        padding: 0px 6%;
        display: flex;
        justify-content: center;
    }
    .header_right_login_inner {
        width: 750px;
        display: flex;
        justify-content: flex-end;
    }
}
/* 로그인페이지 전용 스타일링 End*/

.header_left{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
}
.logo{
    position: relative;
    z-index: 25;
    margin-bottom: 0px;
}
.logo img{
    width: 130px;
}
.gnb{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;
    position: relative;
    z-index: 25;
    margin-bottom: 0;
}
.gnb>li>a{
    display: block;
    width: 100px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    color: #3a3a3a;
}
.haeder_right{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
}
.header_right a {
    display: inline-block;
    width: 90px;
    height: 40px;
    margin-left: 12px;
}
.header_right button{
    width: 90px;
    height: 40px;
    color: #3a3a3a;
    border: 1px solid;
    border-radius: 100px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    transition-duration: 0.5s;
}
.header_right .hover_white_mint {
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    background-color: #fff;
    border: 1px solid #00e2e6;
    transition: 0.2s transform ease-in-out;
}
.header_right .hover_white_mint::after {
    background-color: #00e2e6;
    border-radius: 100px;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    transform: translate(-100%,0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-in-out;
}
@media (hover: hover) and (pointer: fine) {
    .header_right .hover_white_mint:hover::after {
        transform: translate(0,0);
    }
}
.header_right .contact_us {
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    transition: 0.2s transform ease-in-out;
    border: 1px solid #00e2e6;
    background-color: #00e2e6;
    color: #3a3a3a;
}
.header_right .contact_us::after {
    background-color: #00D3E0;
    border-radius: 100px;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    transform: translate(-100%,0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-in-out;
}
@media (hover: hover) and (pointer: fine){
    .header_right .contact_us:hover{
        border-color: #00d3e0;
    }
    .header_right .contact_us:hover::after{
        transform: translate(0,0);
    }
}
.lang{
    font-size: 16px;
    font-weight: 400;
    text-align: end;
    color: #3a3a3a;
    padding-right: 30px;
}
.header_right_m{
    display: none;
}


/* 반응형 */
@media screen and (max-width:1400px){
    .header_wrap{
        max-width: 1000px;
    }
}

/* 테블릿 반응형 */
@media screen and (max-width:1080px){
    .homepage_wrap_header{
        height: 60px;
        padding: 0 20px !important;
        z-index: 12;
    }
    .homepage_wrap_header_login {
        height: 60px;
        padding: 0 20px !important;
        z-index: 12;
    }
    .typeeffect{
        font-size: 40px;
    }
    .logo img{
        width: 110px;
    }
    .header_wrap{
        max-width: none;
    }
    .header_right{
        display: none;
    }
    .header_right_m{
        display: block;
    }
    input[id=menu_m]{
        display: none;
        position: relative;
    }
    input[id=menu_m] + label{
        display: block;
        width: 24px;
        height: 16px;
        position: relative;
        z-index: 20;
        margin-bottom: 0px;
    }
    input[id=menu_m] + label span{
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background: #333;
        transition-duration: 0.2s;
    }
    /* 클릭 안했을 시 */
    input[id="menu_m"] + label span:nth-child(1) {
        top: 0;
    }
    input[id="menu_m"] + label span:nth-child(2) {
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    input[id="menu_m"] + label span:nth-child(3) {
        bottom: 0;
    }
    /* 클릭 시 */
    input[id="menu_m"]:checked + label span:nth-child(1) {
        top: 50%;
        -ms-transform: translateY(-50%) rotate(45deg);
        transform: translateY(-50%) rotate(45deg);
    }
    input[id="menu_m"]:checked + label span:nth-child(2) {
        opacity: 0;
    }
    input[id="menu_m"]:checked + label span:nth-child(3) {
        bottom: 50%;
        -ms-transform: translateY(50%) rotate(-45deg);
        transform: translateY(50%) rotate(-45deg);
    }
    .menu_m{
        position: fixed;
        top: 0;
        right: 0;
        transition-duration: 0.5s;
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-left: 1px solid #ccc;
        overflow: hidden;
        padding: 0 20px;
        padding-top: 80px;
        visibility: hidden;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        box-sizing: border-box;
        opacity: 0;
        z-index: 15;
    }
    input[id="menu_m"]:checked ~ .menu_m{
        visibility: visible;
        opacity: 1;
    }
    .gnb{
        display: none;
    }
    .gnb_m{
        width: 100%;
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .gnb_m li{
        width: 100%;
        margin-bottom: 2px;
    }
    .gnb_m li:last-of-type {
        margin-bottom: 0;
    }
    .gnb_m li a{
        display: block;
        width: 100%;
        height: 50px;
        line-height: 50px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        background-color: #dffafa;
        font-size: 16px;
        font-weight: 400;
        transition-duration: 0.2s;
    }
    .login_m a, .join_m a {
        display: block;
        margin-top: 4px;
        padding-left: 0px;
        text-align: center;
        border-radius: 5px;
        background-color: #fff !important;
        border: 1px solid #00e2e6;
    }
    .gnb_m .login_m a {
        margin-top: 12px;
    }
    .gnb_m .login_m.contact_us_m a {
        margin-top: 4px;
    }
    .contact_us_m a{
        background-color: #00e2e6 !important;
    }
    .lang_m, .lang_m option{
        width: 150px;
        height: 50px;
        border: 1px solid #ccc !important;
        border-radius: 5px;
        margin-top: 12px;
        color: #333;
        font-size: 16px;
        font-weight: 400;
        padding-left: 20px;
        box-sizing: border-box;
        background: url('https://robomd.io/images/home/chevron-down.svg') calc(100% - 16px) center no-repeat;
        background-size: 16px;
    }
}
@media screen and (max-width:620px){
    .homepage_wrap_header{
        padding-left: 10px;
        padding-right: 10px;
    }
}

/* 스타일 가이드 */
@media (hover: hover) and (pointer: fine){
    .hover_white_mint:hover{
        transform: translateZ(0);
        backface-visibility: hidden;
        will-change: transform;
    }
    .hover_white_mint:hover::after{
        transform: translate(0,0);
    }
    .hover_white_mint.disabled:hover::after{
        transform: translate(-100%,0);
    }
}