.grid {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 10px;
    padding: 0 36px;
    padding-top: 28px;
    padding-bottom: 32px;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.20);
    color: #3A3A3A;
    transition: 0.1s;
}
.grid:hover {
    background-color: #F0F5FA;
    color: #3A3A3A;
}
.grid_title {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
}
.grid_title svg {
    width: 40px;
    height: 40px;
}
.grid p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    margin-top: 12px;
}