.page_wrapper{
    padding: 40px;
}
.title{
    margin-top: 60px;
    margin-bottom: 14px;
    font-weight: 500;
    font-size: 18px;
}
.content{
    font-size: 14px;
    margin-bottom: 10px;
}
.radio_button{
    display: block;
}
.radio_button_list{
    display: flex;
}
.radio_button_text{
    font-size: 14px;
    padding: 4px 24px 4px 8px;
}
.check_alert{
    margin-top: 10px;
}
.button_list{
    display: flex;
    align-items: center;
}
.button_list button{
    margin-right: 10px;
}
.popup_frame_button_area button{
    margin-right: 10px;
    margin-bottom: 10px;
}
.popup_frame_title{
    margin-top: 0px;
}
.toggle_area{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.toggle_area > div{
    margin-right: 10px;
}
.list_area{
    overflow: auto;
}
.list {
    margin-top: 20px;
    max-width: 400px;
    min-width: 0px;
}
.list thead tr {
    height: 36px;
}
.list thead tr th {
    vertical-align: middle;
    text-align: center;
    border-right: 2px solid #FFF;
}
.list_row {
    height: 72px;
    border-bottom: 1px solid #EEE;
}

.list_row:hover {
    background-color: #F7F7F7;
}

.list_row td {
    vertical-align: middle;
    cursor: pointer;
    padding: 4px;
}
.button_table{
    max-width: 800px;
}
.tooltip_area{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 300px;
    padding-top: 10px;
}
.header_area > div, .header_area > div > header{
    position: relative;
}
.select_area{
    width: 100%;
    max-width: 800px;
}
.select_area > div {
    margin-bottom: 10px;
}