/* 버튼 - 민트 */
.login_style_guide {
    font-family: "Noto Sans KR";
}
.login_style_guide .hover_mint {
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    transition: 0.2s transform ease-in-out;
    background-color: #00E2E6;
    color: #202020;
}
.login_style_guide .hover_mint::after {
    background-color: #00D3E0;
    border-radius: 100px;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    transform: translate(-100%,0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-in-out;
}
.login_style_guide .hover_mint.disabled {
    background-color: #DFFAFA;
    color: #B7BDC3;
    cursor: default;
}
@media (hover: hover) and (pointer: fine) {
    .login_style_guide .hover_mint:hover {
        transform: translateZ(0);
        backface-visibility: hidden;
        will-change: transform;
        border-color: transparent;
    }
    .login_style_guide .hover_mint:hover::after {
        transform: translate(0,0);
    }
    .login_style_guide .hover_mint.disabled:hover::after {
        transform: translate(-100%,0);
    }
}

/* 버튼 - 그레이 */
.login_style_guide .hover_grey {
    background-color: #ACACAC;
    color: #FFF;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    transition: 0.2s;
}
.login_style_guide .hover_grey::after {
    border-radius: 100px;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    transform: translate(-100%,0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s;
}
.login_style_guide .hover_grey.disabled {
    background-color: #F7F7F7;
    color: #B7BDC3;
    cursor: default;
}
@media (hover: hover) and (pointer: fine) {
    .login_style_guide .hover_grey:hover {
        transform: translateZ(0);
        backface-visibility: hidden;
        will-change: transform;
        border-color: #ACACAC;
        color: #3A3A3A;
    }
    .login_style_guide .hover_grey:hover::after {
        transform: translate(0,0);
    }
    .login_style_guide .hover_grey.disabled:hover::after {
        transform: translate(-100%,0);
    }
}

/* 버튼 - 화이트 테두리 */
.login_style_guide .hover_white_border {
    background-color: #FFF;
    color: #202020;
    border: 1px solid;
    border-color: #ACACAC;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    transition: 0.2s;
}
.login_style_guide .hover_white_border::after {
    background-color: #E3E3E3;
    border-radius: 100px;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    transform: translate(-100%,0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s;
}
.login_style_guide .hover_white_border.disabled {
    background-color: #EEEEEE;
    border-color: #D1D1D1;
    color: #B7BDC3;
    cursor: default;
}
@media (hover: hover) and (pointer: fine) {
    .login_style_guide .hover_white_border:hover {
        transform: translateZ(0);
        backface-visibility: hidden;
        will-change: transform;
    }
    .login_style_guide .hover_white_border:hover::after {
        transform: translate(0,0);
    }
    .login_style_guide .hover_white_border.disabled:hover::after {
        transform: translate(-100%,0);
    }
}

/* 버튼 - 화이트 */
.login_style_guide .hover_white {
    background-color: #FFF;
    color: #3A3A3A;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    transition: 0.2s;
}
.login_style_guide .hover_white::after {
    background-color: #AEF3F2;
    border-radius: 100px;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    transform: translate(-100%,0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s;
}
.login_style_guide .hover_white.disabled {
    background-color: #73EBEC;
    color: #9FA4AA;
    cursor: default;
}
@media (hover: hover) and (pointer: fine) {
    .login_style_guide .hover_white:hover {
        transform: translateZ(0);
        backface-visibility: hidden;
        will-change: transform;
        color: #3A3A3A;
    }
    .login_style_guide .hover_white:hover::after {
        transform: translate(0,0);
    }
    .login_style_guide .hover_white.disabled:hover::after {
        transform: translate(-100%,0);
    }
}

/* input - 넘버 / 텍스트 / 비밀번호 */
.login_style_guide input[type=text], 
.login_style_guide input[type=number], 
.login_style_guide input[type=password] {
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    color: #3A3A3A;
    border: 1px solid #D1D1D1;
    border-radius: 6px;
    padding: 16px 12px;
    transition: 0.2s;
}
.login_style_guide input[type=text]::placeholder, 
.login_style_guide input[type=number]::placeholder, 
.login_style_guide input[type=password]::placeholder {
    font-size: 16px;
    font-weight: 300;
    color: #878B90;
}
.login_style_guide input[type=text]:focus::placeholder, 
.login_style_guide input[type=number]:focus::placeholder, 
.login_style_guide input[type=password]:focus::placeholder {
    color: transparent;
}
.login_style_guide input[type=text]:disabled, 
.login_style_guide input[type=number]:disabled, 
.login_style_guide input[type=password]:disabled {
    outline: none;
    border-color: #D1D1D1;
}
.login_style_guide input[type=text]:focus, 
.login_style_guide input[type=number]:focus, 
.login_style_guide input[type=password]:focus {
    outline: none;
    box-shadow: none;
    border-color: #00E2E6;
}
.login_style_guide input[type=text].error,
.login_style_guide input[type=number].error,
.login_style_guide input[type=password].error {
    outline: none;
    box-shadow: none;
    border-color: #FF085E;
}
.login_style_guide input[type=text].success,
.login_style_guide input[type=number].success,
.login_style_guide input[type=password].success {
    outline: none;
    box-shadow: none;
    border-color: #00E677;
}
@media (hover: hover) and (pointer: fine) {
    .login_style_guide input[type=text]:hover, 
    .login_style_guide input[type=number]:hover, 
    .login_style_guide input[type=password]:hover {
        outline: none;
        border-color: #515151;
    }
    .login_style_guide input[type=text]:disabled:hover, 
    .login_style_guide input[type=number]:disabled:hover, 
    .login_style_guide input[type=password]:disabled:hover {
        border-color: #D1D1D1;
    }
    .login_style_guide input[type=text]:focus:hover, 
    .login_style_guide input[type=number]:focus:hover, 
    .login_style_guide input[type=password]:focus:hover {
        border-color: #00E2E6;
    }
    .login_style_guide input[type=text].error:hover,
    .login_style_guide input[type=number].error:hover,
    .login_style_guide input[type=password].error:hover {
        border-color: #FF085E;
    }
    .login_style_guide input[type=text].success:hover,
    .login_style_guide input[type=number].success:hover,
    .login_style_guide input[type=password].success:hover {
        outline: none;
        box-shadow: none;
        border-color: #00E677;
    }
}

/* 주요 탭 */
.login_style_guide .tab_wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.login_style_guide .tab_wrap .tab + label {
    width: 180px;
    height: 60px;
    background-color: #FFF;
    border-bottom: 2px solid #EEE;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0;
    color: #63666A;
    cursor: pointer;
    transition: 0.2s;
}
.login_style_guide .tab_wrap .tab:checked + label {
    border-color: #00E2E6;
    color: #3A3A3A;
    font-weight: 500;
}

/* 로그인 */
.only_login_page {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.only_login_page .login {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
}
.only_login_page .login_content {
    width: 328px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.only_login_page .logo {
    height: 40px;
}
.only_login_page h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 38px;
    margin-bottom: 0;
    text-align: center;
    color: #3A3A3A;
}
.only_login_page .regist_btn {
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    line-height: 22px;
    margin-top: 16px;
    color: #202020;
}
.only_login_page .divider {
    width: 100%;
    height: 1px;
    margin-top: 36px;
    background-color: rgba(0,0,0,0.12);
}
.only_login_page .login_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 36px;
}
.only_login_page .login_input {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.only_login_page .login_input:last-of-type {
    margin-top: 16px;
}
.only_login_page .login_input label {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 8px;
    color: #202020;
}
.only_login_page .login_input input {
    width: 100%;
    font-size: 13px;
    line-height: 1;
    padding: 11px 16px;
}
.only_login_page .login_input input::placeholder {
    font-size: 13px;
    line-height: 1;
}
.only_login_page .login_input .save_id + label svg:nth-of-type(1) { display: block; }
.only_login_page .login_input .save_id + label svg:nth-of-type(2) { display: none; }
.only_login_page .login_input .save_id:checked + label svg:nth-of-type(1) { display: none; }
.only_login_page .login_input .save_id:checked + label svg:nth-of-type(2) { display: block; }
.only_login_page .login_input .save_id_icn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 0;
}
.only_login_page .login_input .save_id_icn svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.only_login_page .login_input .save_id_icn .checkbox_default .check_icn {
    opacity: 0;
    transition: 0.2s;
}
@media (hover: hover) and (pointer: fine) {
    .only_login_page .login_input .save_id_icn .checkbox_default:hover .check_icn {
        opacity: 1;
    }
}
.only_login_page .login_input .save_id_icn span {
    font-size: 12px;
    font-weight: 350;
    line-height: 1;
    margin-left: 4px;
    color: #3A3A3A;
}
.only_login_page .login_btn {
    width: 100%;
    height: 46px;
    font-size: 15px;
    line-height: 22px;
    margin-top: 40px;
}
.only_login_page .find_my_info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}
.only_login_page .find_my_info h3 {
    font-size: 12px;
    font-weight: 350;
    line-height: 20px;
    margin-bottom: 0;
    color: #000;
}
.only_login_page .find_my_info h4 {
    font-size: 12px;
    font-weight: 350;
    line-height: 20px;
    margin-bottom: 0;
    margin-left: 12px;
    color: #0090FF;
    cursor: pointer;
}

/* 마케팅 영역 */
.only_marketing_area {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #00E2E6;
}
.only_marketing_area * {
    box-sizing: border-box;
}
.only_marketing_area > button {
    width: 328px;
    height: 63px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #FFF;
}
.only_marketing_area > button.hover_white {
    border-radius: 12px;
}
.only_marketing_area > button.hover_white::after {
    border-radius: 12px;
}
.only_marketing_area > button span {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    color: #3A3A3A;
}
.only_marketing_area .sample_banner_btn {
    padding: 0 20px;
}
.only_marketing_area .sample_banner_btn div {
    width: 43px;
    height: 19px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 1;
    margin-right: 12px;
    border-radius: 30px;
    background-color: #B9DDFF;
    color: #0B5DD4;
}
.only_marketing_area .sample_banner_btn svg {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    fill: #515151;
}
.only_marketing_area .service_info_btn {
    padding: 0 32px;
    margin-top: 14px;
}
.only_marketing_area .service_info_btn svg {
    width: 20px;
    height: 20px;
    fill: #515151;
    margin-right: 22px;
}
.only_marketing_area .marketing_area_slider {
    width: 400px;
    height: 400px;
    margin-top: 28px;
}
.only_marketing_area .marketing_area_slider img {
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.only_marketing_area .slick-dots li {
    margin: 0;
}
.only_marketing_area .slick-dots li button:before {
    opacity: 1;
    color: #00C2CD;
}
.only_marketing_area .slick-dots li.slick-active button:before {
    color: #00979A;
}

/* 로그인 & 마케팅 영역 반응형 */
@media screen and (max-width: 888px) {
    /* 로그인 */
    .only_login_page {
        flex-direction: column;
        overflow-x: hidden;
    }
    .only_login_page .login {
        width: 100%;
        height: auto;
    }
    .only_login_page .login_content {
        width: 360px;
        padding: 0 16px;
        margin-top: 64px;
        margin-bottom: 44px;
        box-sizing: border-box;
    }
    .only_login_page .logo {
        height: 29px;
    }
    .only_login_page h2 {
        font-size: 15px;
        line-height: 20px;
        margin-top: 28px;
    }
    .only_login_page .regist_btn {
        width: 100%;
        height: 48px;
        margin-top: 16px;
    }
    .only_login_page .divider {
        width: 100vw;
        height: 8px;
        margin-top: 40px;
        background-color: #F7F7F7;
    }
    .only_login_page  .login_btn {
        margin-top: 32px;
    }

    /* 마케팅 영역 */
    .only_marketing_area {
        width: 100%;
        height: auto;
        padding-top: 40px;
        padding-bottom: 80px;
    }
    .only_marketing_area .marketing_area_slider {
        width: 328px;
        height: 328px;
    }
}

/* 로그인 - 아이디 비밀번호 찾기 */
.only_login_page .dialog_wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.45);
}
.only_login_page .dialog_wrap .dialog {
    width: 360px;
    border-radius: 10px;
    position: relative;
    background-color: #FFF;
}
.only_login_page .dialog_wrap .cancel_btn {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
}
.only_login_page .dialog_wrap .tab_wrap {
    margin-top: 60px;
    margin-bottom: 0;
}
.only_login_page .dialog_wrap .find_my_info_content_wrap {
    width: 100%;
    padding: 32px 16px;
    padding-bottom: 64px;
    box-sizing: border-box;
}
.only_login_page .dialog_wrap .find_my_info_content a,
.only_login_page .dialog_wrap .find_my_info_content button {
    width: 100%;
    height: 46px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
}
.only_login_page .dialog_wrap .find_my_info_common {
    width: 100%;
    height: 224px;
}
.only_login_page .dialog_wrap .find_my_info_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.only_login_page .dialog_wrap .find_my_info_content label {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 8px;
}
.only_login_page .dialog_wrap .find_my_info_content input {
    width: 100%;
    font-size: 13px;
    line-height: 1;
    padding: 10px 16px;
}
.only_login_page .dialog_wrap .find_my_info_content input::placeholder {
    font-size: 13px;
    line-height: 1;
}

/* 로그인 - 아이디 비밀번호 찾기 반응형 */
@media screen and (max-width: 600px) {
    .only_login_page .dialog_wrap {
        justify-content: flex-start;
        align-items: flex-start;
    }
    .only_login_page .dialog_wrap .dialog {
        width: 100%;
        height: 100vh;
        border-radius: 0;
        overflow: auto;
    }
    .only_login_page .dialog_wrap .cancel_btn {
        right: 10px;
    }
    .only_login_page .dialog_wrap .tab_wrap {
        width: 100%;
    }
    .only_login_page .tab_wrap .tab + label {
        width: 50%;
    }
}

/* 로그인 - 아이디 찾기 - 성공 시 */
.only_login_page .dialog_wrap .find_my_id_success {
    width: 100%;
    height: 224px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 16px;
}
.only_login_page .dialog_wrap .find_my_id_success svg {
    width: 40px;
    height: 40px;
    margin-bottom: 16px;
}
.only_login_page .dialog_wrap .find_my_id_success h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 20px;
    text-align: center;
    color: #202020;
}
.only_login_page .dialog_wrap .find_my_id_success .user_info {
    width: 100%;
    height: 100px;
    border-radius: 10px;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.only_login_page .dialog_wrap .find_my_id_success .user_id {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    color: #3A3A3A;
}
.only_login_page .dialog_wrap .find_my_id_success .user_regist_date {
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    color: #878B90;
}

/* 로그인 - 아이디 찾기 - 실패 시 */
.only_login_page .dialog_wrap .find_my_id_fail {
    width: 100%;
    height: 184px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;
}
.only_login_page .dialog_wrap .find_my_id_fail svg {
    width: 40px;
    height: 40px;
    margin-bottom: 16px;
}
.only_login_page .dialog_wrap .find_my_id_fail h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #3A3A3A;
}

/* 로그인 - 비밀번호 찾기 */
.only_login_page .dialog_wrap .find_my_pw_content input {
    margin-bottom: 16px;
}

/* 로그인 - 비밀번호 찾기 - 성공 시 */
.only_login_page .dialog_wrap .find_my_pw_success {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 16px;
}
.only_login_page .dialog_wrap .find_my_pw_success svg {
    width: 40px;
    height: 40px;
    margin-bottom: 16px;
}
.only_login_page .dialog_wrap .find_my_pw_success h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 20px;
    text-align: center;
    color: #3A3A3A;
}
.only_login_page .dialog_wrap .find_my_pw_success ul {
    padding: 0 16px;
    box-sizing: border-box;
    list-style: circle;
}
.only_login_page .dialog_wrap .find_my_pw_success li {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    word-break: keep-all;
    color: #878B90;
    position: relative;
}
.only_login_page .dialog_wrap .find_my_pw_success span {
    position: absolute;
    left: -9px;
    top: 7px;
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #878B90;
}

/* 로그인 - 비밀번호 찾기 - 실패 시 */
.only_login_page .dialog_wrap .find_my_pw_fail {
    width: 100%;
    height: 184px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;
}
.only_login_page .dialog_wrap .find_my_pw_fail svg {
    width: 40px;
    height: 40px;
    margin-bottom: 16px;
}
.only_login_page .dialog_wrap .find_my_pw_fail h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #3A3A3A;
}

/* 비밀번호 재설정 */
.pwd_reset_wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #3A3A3A;
}
.pwd_reset {
    width: 368px;
    margin-top: 140px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.pwd_reset h2 {
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 36px;
}
.pwd_reset span {
    font-weight: 500;
}
.pwd_reset p {
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    margin-bottom: 8px;
    color: #202020;
}
.pwd_reset form {
    width: 100%;
    margin-bottom: 48px;
}
.pwd_reset .input_wrap {
    width: 100%;
    position: relative;
    margin-bottom: 8px;
}
.pwd_reset .input_wrap:last-of-type {
    margin-bottom: 0;
}

/* 비밀번호 재설정 - 인풋 */
.pwd_reset input[type="text"], .pwd_reset input[type="password"] {
    width: 100%;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    padding: 11px 16px;
    padding-right: 32px;
    transition: 0s;
}
.pwd_reset input[type="text"]::placeholder, .pwd_reset input[type="password"]::placeholder {
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    color: #D1D1D1;
}
.pwd_reset input[type="text"].error, .pwd_reset input[type="password"].error {
    background: none;
}
.pwd_reset input[type="text"].success, .pwd_reset input[type="password"].success {
    background: none;
}

/* 비밀번호 재설정 - 숨김/보임 버튼 */
.pwd_reset input[type="checkbox"] {
    display: none;
}
.pwd_reset input[type="checkbox"] + label img {
    width: 20px;
    position: absolute;
    z-index: 2;
    right: 12px;
    top: 20px;
    transform: translateY(-50%);
    cursor: pointer;
}
.pwd_reset input[type="checkbox"] + label img:nth-of-type(1) { display: block; }
.pwd_reset input[type="checkbox"] + label img:nth-of-type(2) { display: none; }
.pwd_reset input[type="checkbox"]:checked + label img:nth-of-type(1) { display: none; }
.pwd_reset input[type="checkbox"]:checked + label img:nth-of-type(2) { display: block; }
.pwd_reset button {
    width: 100%;
    height: 46px;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
}

/* 비밀번호 재설정 - 에러 메세지 */
.pwd_reset .error_msg {
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 16px;
    color: #ff085e;
}

/* 비밀번호 재설정 - 다이얼로그 */
.pwd_reset_wrap .dialog_wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
}
.pwd_reset_wrap .dialog_wrap .dialog_insert_txt span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    margin-top: 8px;
    color: #3A3A3A;
}

/* 비밀번호 재설정 - 반응형 */
@media screen and (max-width: 1080px) {
    .pwd_reset {
        width: 360px;
        margin-top: 108px;
        padding: 0 16px;
        box-sizing: border-box;
    }
    input[type=text].error + .error_msg, input[type=password].error + .error_msg {
        padding-left: 8px;
        font-size: 12px;
    }
}

/* 회원가입 */
.only_regist_user_page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 170px;
    padding-bottom: 130px;
}
.only_regist_user_page * {
    box-sizing: border-box;
}
.only_regist_user {
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 16px;
}
.only_regist_user_page h2 {
    position: relative;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0;
    color: #3A3A3A;
}
.only_regist_user_page h2:last-of-type {
    margin-top: 32px;
}
.only_regist_user_page h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    margin-top: 16px;
    margin-bottom: 0;
    color: #202020;
}
.only_regist_user_page h3:first-of-type {
    margin-top: 32px;
}
.only_regist_user_page h3 span {
    margin-left: 2px;
    color: #FF085E;
}
.only_regist_user_page form {
    width: 100%;
}
.only_regist_user_page .regist_input {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    margin-top: 8px;
}
.only_regist_user_page .regist_input input {
    transition: 0s;
}
.only_regist_user_page .regist_input_with_bg input.error {
    background: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' fill='white'/%3E%3Cpath d='M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1229 7.84575 17.2662 5.78033 15.743 4.25704C14.2197 2.73376 12.1543 1.87707 10 1.875ZM9.375 6.25C9.375 6.08424 9.44085 5.92527 9.55806 5.80806C9.67527 5.69085 9.83424 5.625 10 5.625C10.1658 5.625 10.3247 5.69085 10.4419 5.80806C10.5592 5.92527 10.625 6.08424 10.625 6.25V10.625C10.625 10.7908 10.5592 10.9497 10.4419 11.0669C10.3247 11.1842 10.1658 11.25 10 11.25C9.83424 11.25 9.67527 11.1842 9.55806 11.0669C9.44085 10.9497 9.375 10.7908 9.375 10.625V6.25ZM10 14.375C9.81458 14.375 9.63333 14.32 9.47916 14.217C9.32499 14.114 9.20482 13.9676 9.13387 13.7963C9.06291 13.625 9.04434 13.4365 9.08052 13.2546C9.11669 13.0727 9.20598 12.9057 9.33709 12.7746C9.4682 12.6435 9.63525 12.5542 9.81711 12.518C9.99896 12.4818 10.1875 12.5004 10.3588 12.5714C10.5301 12.6423 10.6765 12.7625 10.7795 12.9167C10.8825 13.0708 10.9375 13.2521 10.9375 13.4375C10.9375 13.6861 10.8387 13.9246 10.6629 14.1004C10.4871 14.2762 10.2486 14.375 10 14.375Z' fill='%23FF085E'/%3E%3C/svg%3E") no-repeat;
    background-position: calc(100% - 15px) 50%;
    background-size: 20px 20px;
}
.only_regist_user_page .regist_input_with_bg input.success {
    background: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' fill='white'/%3E%3Cpath d='M8.1251 15C7.95944 14.9977 7.80061 14.9336 7.67979 14.8203L3.30479 10.4453C3.20512 10.3239 3.15419 10.1697 3.1619 10.0128C3.1696 9.85587 3.2354 9.70744 3.34648 9.59636C3.45756 9.48527 3.606 9.41948 3.7629 9.41177C3.91981 9.40407 4.07398 9.455 4.19541 9.55466L8.1251 13.4922L16.4298 5.17966C16.5512 5.08 16.7054 5.02907 16.8623 5.03677C17.0192 5.04448 17.1676 5.11027 17.2787 5.22136C17.3898 5.33244 17.4556 5.48087 17.4633 5.63778C17.471 5.79469 17.4201 5.94885 17.3204 6.07029L8.57041 14.8203C8.44958 14.9336 8.29075 14.9977 8.1251 15Z' fill='%2300E677'/%3E%3C/svg%3E%0A") no-repeat;
    background-position: calc(100% - 15px) 50%;
    background-size: 20px 20px;
}
.only_regist_user_page .regist_input input ~ span {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.25;
    margin-left: 12px;
}
.only_regist_user_page .regist_input input.success ~ span {
    margin-top: 4px;
    color: #878B90;
}
.only_regist_user_page .regist_input input.error ~ span {
    margin-top: 4px;
    color: #FF085E;
}
.only_regist_user_page input[type="text"],
.only_regist_user_page input[type="password"] {
    width: 100%;
    height: 40px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    padding: 0 16px;
    color: #3A3A3A;
}
.only_regist_user_page input[type="text"]::placeholder,
.only_regist_user_page input[type="password"]::placeholder {
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    color: #878B90;
}
.only_regist_user_page .regist_visible_chk {
    height: 20px;
    position: absolute;
    right: 12px;
    top: 0;
    transform: translateY(50%);
}
.only_regist_user_page .regist_visible_chk input + label {
    height: 100%;
    margin-bottom: 0;
    cursor: pointer;
}
.only_regist_user_page .regist_visible_chk input + label img {
    height: 100%;
}
.only_regist_user_page .regist_visible_chk input + label img:nth-of-type(1) { display: block; }
.only_regist_user_page .regist_visible_chk input + label img:nth-of-type(2) { display: none; }
.only_regist_user_page .regist_visible_chk input:checked + label img:nth-of-type(1) { display: none; }
.only_regist_user_page .regist_visible_chk input:checked + label img:nth-of-type(2) { display: block; }
.only_regist_user_page .divider {
    width: 360px;
    height: 8px;
    background-color: #F7F7F7;
    transform: translateX(-16px);
    margin-top: 32px;
}
.only_regist_user_page .agree_label_wrap {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 16px;
}
.only_regist_user .agree_all_label_wrap {
    margin-top: 24px;
}
.only_regist_user_page .agree_label_wrap label {
    width: 20px;
    height: 20px;
    margin-bottom: 0;
    margin-right: 4px;
    cursor: pointer;
}
.only_regist_user .agree_label_wrap svg {
    width: 100%;
    height: 100%;
}
.only_regist_user_page .agree_label_wrap a {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.only_regist_user_page .agree_label_wrap .terms_btn {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.only_regist_user_page .agree_input + label .checkbox_default { display: block; }
.only_regist_user_page .agree_input + label .checkbox_checked { display: none; }
.only_regist_user_page .agree_input:checked + label .checkbox_default { display: none; }
.only_regist_user_page .agree_input:checked + label .checkbox_checked { display: block; }
.only_regist_user_page .agree_all_txt {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: #3A3A3A;
}
.only_regist_user_page .agree_divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0,0,0,0.12);
    margin-top: 16px;
}
.only_regist_user_page .agree_txt {
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    color: #3A3A3A;
}
.only_regist_user_page .regist_complete_btn {
    width: 100%;
    height: 46px;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 40px;
}
.only_regist_user_page .faq {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 350;
    line-height: 20px;
    margin-top: 9px;
    color: #000;
}
.only_regist_user_page .faq span {
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    margin-left: 12px;
    color: #0090FF;
}
@media screen and (max-width: 1080px) {
    .only_regist_user_page {
        padding-top: 108px;
        padding-bottom: 28px;
    }
}

/* 토스트 메세지 */
.only_regist_user_page h2 .toast_msg {
    width: max-content;
    display: none;
    position: absolute;
    z-index: 2;
    left: 50%;
    bottom: -12px;
    transform: translate(-50%, 100%);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding: 16px 24px;
    border-radius: 10px;
    background-color: #515151;
    color: #FFF;
    cursor: pointer;
}
.only_regist_user_page h2 .toast_msg.hidden {
    display: none;
}
.only_regist_user_page h2 .toast_msg::before {
    content: "";
    width: 12px;
    height: 12px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: #515151;
}
.only_regist_user_page h2 .toast_msg span {
    font-size: 13px;
    font-weight: 700;
}
.only_regist_user_page h2 .toast_popup {
    display: block;
    animation: toast_popup 3s 1 both;
}
@keyframes toast_popup {
    0% {
        opacity: 0;
        bottom: 0;
    }
    20% {
        opacity: 1;
        bottom: -12px;
    }
    80% {
        opacity: 1;
        bottom: -12px;
    }
    100% {
        opacity: 0;
        bottom: 0;
    }
}

/* 회원가입 인증메일 */
.only_regist_confirm_page {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.only_regist_confirm_page * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.only_regist_confirm_page .regist_confirm {
    width: 328px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 170px;
}
.only_regist_confirm_page h2 {
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
    color: #3A3A3A;
}
.only_regist_confirm_page h2 span {
    font-weight: 500;
}
.only_regist_confirm_page h3 {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 20px;
    color: #3A3A3A;
}
.only_regist_confirm_page ul {
    width: 100%;
    position: relative;
    padding-left: 12px;
    margin-bottom: 72px;
}
.only_regist_confirm_page li {
    position: relative;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #878B90;
}
.only_regist_confirm_page li::before {
    content: "";
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    position: absolute;
    left: -10px;
    top: 8px;
    background-color: #878B90;
}
.only_regist_confirm_page h4 {
    font-size: 12px;
    font-weight: 350;
    line-height: 20px;
    margin-bottom: 8px;
    color: #000;
}
.only_regist_confirm_page button {
    width: 100%;
    height: 46px;
}
.only_regist_confirm_page button:first-of-type {
    margin-bottom: 8px;
}
@media screen and (max-width: 1080px) {
    .only_regist_confirm_page .regist_confirm {
        margin-top: 108px;
    }
    .only_regist_confirm_page ul {
        margin-bottom: 40px;
    }
}

/* 회원가입 인증메일 - 토스트 메세지 */
.only_regist_confirm_page .toast_msg {
    width: max-content;
    display: none;
    position: absolute;
    z-index: 2;
    left: 50%;
    bottom: 12px;
    transform: translate(-50%, 100%);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding: 12px 24px;
    border-radius: 20px;
    background-color: #515151;
    color: #FFF;
}
.only_regist_confirm_page .toast_popup {
    display: block;
    animation: regist_confirm_toast_popup 3s 1 both;
}
@keyframes regist_confirm_toast_popup {
    0% {
        opacity: 0;
        bottom: 0;
    }
    20% {
        opacity: 1;
        bottom: 12px;
    }
    80% {
        opacity: 1;
        bottom: 12px;
    }
    100% {
        opacity: 0;
        bottom: 0;
    }
}

/* 약관 */
.only_terms_page {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    background-color: #EFF2F5; 
}
.only_terms_page * {
    box-sizing: border-box;
}
.only_terms_page .terms {
    width: 500px;
    position: relative;
    margin-top: 0;
    background-color: #FFF;
}
.only_terms_page .terms_header {
    width: 100%;
    height: 72px;
    padding-bottom: 12px;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    background-color: #FFF;
}
.only_terms_page .terms_header h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 0;
}
.only_terms_page .close_btn {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 16px;
    top: calc(50% - 8px);
    transform: translateY(-50%);
    cursor: pointer;
}
.only_terms_page .terms_body {
    width: 100%;
    height: calc(100vh - 72px);
    margin-top: 72px;
    padding: 20px 0;
    overflow: scroll;
}
.only_terms_page .terms_body pre {
    width: 100%;
    padding: 0 16px;
    margin-bottom: 0;
    font-family: "Noto Sans KR";
    font-size: 12px;
    font-weight: 400;
    line-height: 1.25;
    text-align: left;
    white-space: pre-wrap;
    word-break: keep-all;
    color: #63666A;
    overflow: hidden;
}
@media screen and (max-width: 500px) {
    .only_terms_page .terms {
        width: 100%;
    }
}