.wrap {
    width: 100%;
    max-width: 1080px;
    min-height: calc(100vh - 72px);
    display: flex;
    flex-direction: column;
    font-family: "Noto Sans KR", "Roboto", sans-serif;
    font-weight: 400;
    color: #3A3A3A;
    padding: 40px;
    padding-bottom: 144px;
}
.wrap > h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
}
.wrap .section:first-of-type {
    margin-top: 32px;
}
.section {
    margin-top: 46px;
}
.section > h3 {
    font-size: 16px;
}
.list_wrap {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.list,
.list_align_start {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 32px 56px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.20);
}
.list {
    justify-content: space-between;
}
.list_align_start {
    justify-content: flex-start;
    gap: 80px;
}
.list_name {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 18px;
    margin-top: 1px;
}
.list_trial {
    padding: 6px 8px;
    border-radius: 20px;
    font-size: 12px;
    line-height: 1;
    color: #202020;
    background-color: #AEF3F2;
}
.list_use_finish {
    padding: 6px 8px;
    border-radius: 20px;
    font-size: 12px;
    line-height: 1;
    color: #202020;
    background-color: #E3E3E3;
}
.list_divider {
    width: 1px;
    height: 16px;
    background-color: #CCC;
}
.list_content {
    display: flex;
    align-items: center;
    gap: 10px;
}
.list_content > h4 {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
}
.list_content > div {
    font-size: 18px;
}
.grid_section {
    margin-top: 68px;
}
.grid_section > h3 {
    font-size: 16px;
}
.grid_wrap {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: stretch;
    gap: 20px 0px;
    margin-top: 30px;
}
.grid {
    width: 32%;
}
.grid_empty {
    width: 32%;
}

@media screen and (max-width: 1280px) {
    .wrap {
        padding: 40px 20px;
        padding-bottom: 100px;
    }
    .list,
    .list_align_start {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 24px;
        padding: 36px;
    }
    .list_divider {
        display: none;
    }
    .grid_wrap {
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
    }
    .grid {
        width: 100%;
    }
    .grid_empty {
        display: none;
    }
}