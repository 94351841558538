/* 이미지 타입 선택 팝업 */
:global .detail_img_link_type_popup .common_popup_frame{
    position: fixed;
    align-items: center;
}
:global .detail_img_link_type_popup .common_popup_frame_div{
    align-items: center;
}
@media (max-height:450px){
    :global .detail_img_link_type_popup .common_popup_frame{
        align-items: flex-start;
    }
}
.link_type_popup svg{
    margin-bottom: 16px;
}
.link_type_popup .button_area{
    margin-bottom: 8px;
}
.link_type_popup .button_area label{
    width: 190px;
    height: 34px;
    font-size: 13px;
    align-items: center;
    display: grid;
    cursor: pointer;
    margin-bottom: 0px;
    text-align: center;
}
.link_type_popup .text{
    color: #878B90;
    margin: 8px 12px;
    font-size: 14px;
    text-align: center;
}
@media (max-width:800px) {
    .link_type_popup .button_area{
        display: block;
    }
}

/* 슬라이더 팝업 */
:global .select_detail_crop_img_popup{
    position: fixed;
}

.slider_area{
    width: 100%;
    padding: 0px 30px;
}
.slider2{
    margin-top: 60px;
    margin-bottom: 20px;
    pointer-events: none;
}
.slider2 img{
    width: auto !important;
    height: auto !important;
    max-width: 400px;
    max-height: 400px;
    object-fit: contain !important;
    flex: none !important;
    margin: auto;
    cursor: default !important;
}
.slider_area .img_area{
    display: flex;
}
.slider_area .img_area img{
    width: 140px;
    height: 140px;
    flex: none;
    margin: auto;
    object-fit: contain;
    -webkit-user-drag: none;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #ACACAC;
}
.slick-prev:before, .slick-next:before{
    color : black !important;
}
.slider_area .selected_slick{
    border: 3px solid #00E2E6 !important;
}
.slider_area .button_area{
    display: flex;
    justify-content: center;
}
.slider_area .button_area button{
    width: 200px;
    height: 40px;
    font-size: 14px;
    margin : 0 10px;
}
:global .select_detail_crop_img_popup_slider .slick-arrow{
    background-size: contain !important;
    background-repeat: no-repeat !important;
}
:global .select_detail_crop_img_popup_slider .slick-arrow:before{
    content : none !important;
    width: 22px !important;
    height: 22px !important;
}
:global .select_detail_crop_img_popup_slider .slick-prev{
    background-image: url("../../../resources/img/arrow_before.svg") !important;
}
:global .select_detail_crop_img_popup_slider .slick-next{
    background-image: url("../../../resources/img/arrow_after.svg") !important;
}
@media (max-width:800px) {
    .slider_area .img_area img{
        width: 100px;
        height: 100px;
    }
    .slider_area .button_area button{
        width: 160px;
        font-size: 12px;
        margin: 0 6px;
    }
}
@media (max-width:550px) {
    .slider2 img{
        max-width: 100%;
    }
}
@media (max-width:400px) {
    .slider_area .button_area button{
        font-size: 11px;
    }
}