@media (min-width: 1500px) {
    .products_wrap .products > li:nth-child(4n) .conts {
        right: 0;
        left: inherit;
    }
    .products_wrap .products > li:nth-child(5n) .conts {
        right: 0;
        left: inherit;
    }
}
@media (max-width: 1500px) {
    .content_area {
        width: 68.75rem;
    }
    .products_wrap .products > li {
        max-width: 14rem;
        margin-right: 2rem;
    }
    .products_wrap .products > li:nth-child(5n) {
        margin-right: 2rem;
    }
    .products_wrap .products > li:nth-child(4n) {
        margin-right: 0;
    }

    .products_wrap .products > li:nth-child(4n) .conts {
        right: 0;
        left: inherit;
    }
    .products_wrap .products > li:nth-child(5n) .conts {
        right: inherit;
    }

    .rank .content_item .content ul li {
        max-width: 14rem;
        margin-right: 2rem;
    }
    .rank .content_item .content ul li:nth-child(5n) {
        margin-right: 2rem;
    }
    .rank .content_item .content ul li:nth-child(4n) {
        margin-right: 0;
    }
    .content_item .progress-wrap {
        margin: 0 1.875rem 0 3.75rem
    }
    .content_item ul li p {
        font-size: 1rem;
    }
    .content_item .progress {
        max-width: 15rem;
    }

    .rank .products_wrap .products li:nth-child(2n) {
        margin-right: 0;
    }
}

@media (max-width: 1440px) {
    .content_area {
        width: 60rem;
    }
    .products_wrap .products > li {
        max-width: 16.375rem;
    }
 
    .products_wrap .products > li:nth-child(4n) {
        margin-right: 2rem;
    }
    .products_wrap .products > li:nth-child(3n) {
        margin-right: 0;
    }
    .products_wrap .products > li:nth-child(3n) .conts {
        right: 0;
        left: inherit;
    }
    .products_wrap .products > li:nth-child(4n) .conts {
        right: inherit;
    }


    .rank .content_item .content ul li {
        max-width: 16.375rem;
    }
    
    .rank .content_item .content ul li:nth-child(2n) {
        margin-right: 2rem;
    }
    .rank .content_item .content ul li:nth-child(4n) {
        margin-right: 2rem;
    }
    .rank .content_item .content ul li:nth-child(3n) {
        margin-right: 0;
    }


    .content_item .progress-wrap {
        margin: 0 1.875rem;
    }
    .content_item .progress {
        max-width: 12rem;
    }


    .search .search-wrap label {
        margin-right: 1.875rem;
    }
    .search .search-wrap select {
        margin-left: 1rem;
    }
}

@media (max-width: 1280px)  {
    .content_area {
        width: 53.75rem;
    }
    .products_wrap .products > li {
        width: 14.5rem;
    }

    .rank .content_item .content ul li {
        width: 14.5rem;
    }

    .content_item .progress {
        max-width: 9.25rem;
    }


    .search .search-wrap .search-area input {
        width: 12rem;
        background-size: 17px 17px;
        padding-left: 1.5rem;
        background-position: left center;
    }
    .search .search-wrap .search-area input::placeholder {
        font-size: 0.75rem;
    }
    .search .search-wrap label {
        margin-right: 1rem;
    }
    .search .search-wrap label p,
    .search .search-wrap select {
        font-size: 0.75rem;
    }
    .search .search-wrap select {
        margin-left: 0.5rem;
        padding-right: 1rem;
    }
}

@media (max-width: 1140px) {
    .content_area {
        width: 45rem;
    }
    .products_wrap .products > li {
        width: 11.875rem;
        margin-right: 1.5rem;
        margin-bottom: 3rem;
    }
    .products_wrap .products > li:nth-child(4n) {
        margin-right: 1.5rem;
    }
    .products_wrap .products > li:nth-child(2n) {
        margin-right: 1.5rem;
    }
    .products_wrap .products > li:nth-child(5n) {
        margin-right: 1.5rem;
    }
    .products_wrap .products > li:nth-child(3n) {
        margin-right: 0;
    }

    .products_wrap .products .cont_bt p {
        margin-top: 0.9375rem;
    }


    .rank .content_item .content ul li {
        width: 11.875rem;
        margin-right: 1.5rem;
        margin-bottom: 3rem;
    }   
    .rank .content_item .content ul li:nth-child(4n) {
        margin-right: 1.5rem;
    }
    .rank .content_item .content ul li:nth-child(2n) {
        margin-right: 1.5rem;
    }
    .rank .content_item .content ul li:nth-child(5n) {
        margin-right: 1.5rem;
    }
    .rank .content_item .content ul li:nth-child(3n) {
        margin-right: 0;
    }

    .content_item .progress-wrap {
        margin: 0 1rem;
    }
    .content_item .progress {
        max-width: 7.5rem;
    }
    .content_item ul li p {
        width: 3.75rem;
        font-size: 0.875rem;
    }
    .content_item ul li span {
        font-size: 0.875rem;
    }
    .content_title {
        margin-bottom: 2.25rem;
    }


    .rank .content_title {
        align-items: baseline;
        flex-direction: column;
    }
    .rank .content_title h1 {
        margin-bottom: 0.9375rem;
    }
}

@media (max-width: 992px) {
    .content_area {
        width: 40rem;
    }
    .products_wrap .products > li {
        width: 16rem;
    }
    .products_wrap .products > li:nth-child(3n) {
        margin-right: 1.5rem;
    }
    .products_wrap .products > li:nth-child(2n) {
        margin-right: 0;
    }
    .products_wrap .products > li:nth-child(3n) .conts {
        right: inherit;
    }
    .products_wrap .products > li:nth-child(2n) .conts {
        right: 0;
        left: inherit;
    }



    .rank .content_item .content ul li {
        width: 16rem;
    }   
    .rank .content_item .content ul li:nth-child(3n) {
        margin-right: 1.5rem;
    }
    .rank .content_item .content ul li:nth-child(2n) {
        margin-right: 0;
    }

    .content_item .progress {
        max-width: 5rem;
    }



    .search .align_tab ul li {
        font-size: 0.9375rem;
    }
    .search .category_wrap p {
        font-size: 0.9375rem;
        margin-right: 0.5rem;
    }
    .search .category_wrap label {
        width: 5rem;
    }
    .search .category_wrap label input {
        font-size: 0.9375rem;
    }
    .search .category_wrap i {
        width: 20px;
        height: 20px;
        background-size: 100%;
    }

    .search .search-wrap label {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 900px) {
    .products li .conts {
        min-width: 23.8125rem;
    }



    .content_area {
        width: 35rem;
    }
    .content_area .content_header {
        padding: 0 1.875rem;
    }
    .date_wrap .date p {
        font-size: 0.9375rem;
    }
    .date_wrap .date span {
        font-size: 0.8125rem;
    }
    .content_tabs ul li a {
        font-size: 0.9375rem;
    }
    .content_header select {
        font-size: 0.9375rem;
    }

    .products_wrap .products_container {
        padding: 1.875rem
    }
    .products_wrap .products > li {
        max-width: 14.75rem;
    }
    .products_wrap .products > li:nth-child(2n) {
        margin-right: 0;
    }


    .rank .content_item .content ul li {
        max-width: 14.75rem;
    }   
    .rank .content_item .content ul li:nth-child(2n) {
        margin-right: 0;
    }

    .content_item .content {
        padding: 1.875rem;
    }

    .rank .result_wrap {
        padding: 0 1.875rem;
    }



    .search .search-wrap {
        padding: 0 1.875rem;
    }
    .search .select-wrap label > p {
        margin-bottom: 0.25rem;
    }
    .search .select-wrap label > div {
        display: flex;
        align-items: center;
    }
    .search .search-btn button {
        font-size: 0.75rem;
    }
}

@media (max-width: 800px) {
    .container-fluid > .row {
        margin-left: 0;
        margin-right: 0;
    }
    .menu_area {
        display: none;
    }
    .contents_area {
        padding: 8.125rem 0 0 0;
    }
    .content_area {
        width: 95%;
    }
    .date_wrap .date span {
        margin-left: 0.5rem;
    }
    .content_tabs ul li {
        margin-left: 0.75rem;
    }
    .products_wrap .products > li {
        max-width: 11.5rem;
    }
    .products_wrap .products > li:nth-child(2n) {
        margin-right: 1.5rem;
    }
    .products_wrap .products > li:nth-child(3n) {
        margin-right: 0;
    }
    .products_wrap .products > li:nth-child(3n) .conts {
        right: 0;
    }
    .products_wrap .products > li:nth-child(2n) .conts {
        right: inherit;
    }


    .rank .content_item .content ul li {
        max-width: 11.5rem;
    }   
    .rank .content_item .content ul li:nth-child(2n) {
        margin-right: 1.5rem;
    }
    .rank .content_item .content ul li:nth-child(3n) {
        margin-right: 0;
    }

    .content_item {
        flex-direction: column;
    }
    .content_item .content {
        width: 100%;
    }
    .content_item .content:last-child {
        margin-left: 0;
        margin-top: 1.25rem;
    }

    .content_item .content .total p {
        margin-bottom: 2rem;
    }
    .content_item .progress {
        max-width: 17.5rem;
        margin: 0 1.875rem 0 4.875rem;
    }
    .content_item ul li {
        justify-content: space-between;
    }
    .content_item ul li p {
        width: 4.625rem;
        font-size: 1.25rem;
    }
    .content_item ul li span {
        font-size: 1.125rem;
    }


    .pagination_wrap .pagination_container {
        justify-content: center;
        padding: 0;
        margin-top: 1.875rem;
    }
    .pagination_wrap .pagination_prev {
        margin-right: 0.5rem;
    }
    .pagination_wrap .pagination_next {
        margin-left: 0.5rem;
    }
}

@media (max-width: 768px) {
    .products_wrap .products > li {
        width: 30%;
    }
    .rank .content_item .content ul li {
       width: 30%;
    }   
}

@media (max-width: 640px) {
    .products_wrap .products > li {
        max-width: 45%;
        width: 100%;
    }
    .products_wrap .products > li:nth-child(3n) {
        margin-right: 1.5rem;
    }
    .products_wrap .products > li:nth-child(2n) {
        margin-right: 0;
    }
    .products_wrap .products > li:nth-child(3n) .conts {
        right: inherit;
    }
    .products_wrap .products > li:nth-child(2n) .conts {
        right: -1.8rem;
        left: inherit;
    }

    .rank .content_item .content ul li {
        max-width: 45%;
        width: 100%;
    }  
    .rank .content_item .content ul li:nth-child(3n) {
        margin-right: 1.5rem;
    }
    .rank .content_item .content ul li:nth-child(2n) {
        margin-right: 0;
    } 
    .rank .content_title .help span {
        display: block;
        margin-left: 1.875rem;
    }

    .products li .conts {
        left: -0.9375rem;
    }
}

@media (max-width: 586px) {
    .content_area .content_header {
        padding: 0.9375rem;
    }

    .content_header select {
        margin-left: 0;
    }
    .go-top {
        bottom: 1.5rem;
        right: 1.5rem;
    }
    .go-top button {
        width: 2rem;
        height: 2rem;
        background-size: 1rem;
    }


    .content_item .progress {
        margin: 0 1.875rem;
    }



    .search .search-wrap .search-area input {
        width: 9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
@media (max-width: 440px) {
    .products li .conts {
        min-width: 20.8125rem;
        padding: 1rem;
    }
}
@media (max-width: 414px) {
    .content_area {
        width: 100%;
    }
    
    .products_wrap .products .cont_top h3 {
        font-size: 1rem;
    }
    .products_wrap .products .cont_top .icon .img_area,
    .products_wrap .products .cont_top .icon .number {
        width: 1.25rem;
        height: 1.25rem;
    }
    .products_wrap .products .cont_top .icon .number p {
        line-height: inherit;
        font-size: 0.75rem;
    }
    .products_wrap .products .cont_top {
        margin-bottom: 0.5rem;
    }
    .products_wrap .products .cont_bt p {
        font-size: 0.75rem;
        margin: 0.625rem 0;
    }
    .products_wrap .products .cont_bt span {
        font-size: 0.75rem;
    }
    .date_wrap .date {
        margin: 0 0.5rem;
    }
    .date_wrap .date p {
        font-size: 0.75rem;
    }
    .date_wrap .date span {
        font-size: 0.75rem;
    }
    .content_tabs ul li:first-child {
        margin-left: 0;
    }
    .content_tabs ul li a {
        font-size: 0.75rem;
    }
    .content_header select {
        font-size: 0.75rem;
    }

    .products_wrap .products_container {
        padding: 1.875rem 0.9375rem;
    }
    .products_wrap .products > li {
        margin-right: 0.9375rem;
    }
    .products_wrap .products > li:nth-child(5n) {
        margin-right: 0.9375rem;
    }
    .products_wrap .products > li:nth-child(3n) {
        margin-right: 0.9375rem;
    }
    .products_wrap .products > li:nth-child(2n) {
        margin-right: 0;
    }

    .pagination_wrap .pagination_num li {
        width: 1.25rem;
        height: 1.25rem;
    }
    .pagination_wrap .pagination_prev{
        margin-right: 0.9375rem;
    }
    .pagination_wrap .pagination_next{
        margin-left: 0.9375rem;
    }

    .rank .content_item .content ul {
        justify-content: space-between;
    }
    .rank .content_item .content ul li {
        margin-right: 0.9375rem;
    }  
    .rank .content_item .content ul li:nth-child(5n) {
        margin-right: 0.9375rem;
    }
    .rank .content_item .content ul li:nth-child(3n) {
        margin-right: 0.9375rem;
    }
    .rank .content_item .content ul li:nth-child(2n) {
        margin-right: 0;
    }
    .content_item .progress {
        max-width: 12rem;
        margin: 0 1rem;
    }
    .content_item .content .title h1 {
        font-size: 1rem;
    }
    .content_item .content .total p {
        font-size: 0.9875rem;
    }
    .content_item ul li p {
        font-size: 0.875rem;
    }
    .content_item ul li span {
        font-size: 0.875rem;
    }


    .search .content_header {
        flex-direction: column;
        height: inherit;
    }
    .search .align_tab {
        margin-bottom: .5rem;
    }
    .search .search-wrap {
        flex-direction: column;
        padding: 0.9375rem;
        height: inherit;
    }
    .search .search-area {
        margin-bottom: .5rem;
    }
}

@media (max-width: 375px) {
    .products li .conts {
        min-width: 15.8125rem;
    }


    .date_wrap .date {
        margin: 0 0.5rem;
    }
    .date_wrap .date p {
        font-size: 0.625rem;
    }
    .date_wrap .date span {
        font-size: 0.625rem;
    }
    .content_tabs ul li a {
        font-size: 0.625rem;
    }
    .content_header select {
        padding: 0 1rem 0 0.5rem;
        font-size: 0.625rem;
        background-size: 0.625rem;
        background-position: 90% 52%;
    }

    .products_wrap .products > li {
        margin-bottom: 1.5rem;
    }
    .products_wrap .products .cont_md {
        height: 7rem;
    }
    .md-none {
        display: none !important;
    }
    
    .rank .content_item .content ul li {
        margin-bottom: 1.5rem;
    }  
    .rank .content_item .content ul li .img-area {
        height: 7rem;
    }
    .rank .content_item .content ul li .text-area h3 {
        font-size: 0.625rem;
    }
    .rank .content_item .content ul li .text-area p {
        font-size: 0.625rem;
    }
    .rank .content_item .content ul li .text-area del {
        font-size: 0.625rem;
    }
    .rank .content_item .content ul li .text-area span {
        font-size: 0.625rem;
    }
    .products_wrap .products .cont_md {
        height: 7rem;
    }
    .content_item .progress {
        max-width: 9rem;
    }
}

@media (max-width: 320px) {
    .date_wrap .date span {
        margin-left: 0.5rem;
    }
    .content_tabs ul li {
        margin-left: 0.5rem;
    }
    .date_wrap .date_input .date_prev,
    .date_wrap .date_input .date_next {
        width: 4px;
        height: 10px;
        background-size: 4px 10px;
        background-position: center;
    }

    .content_item .progress {
        max-width: 6rem;
    }
}

@media (max-width: 300px) {
    .content_area .content_header {
        padding: 0.9375rem 0.625rem;
    }
    .contents_area {
        max-width: 100%;
    }
    .content_header select {
        padding: 0 0.75rem 0 0.25rem;
    }
    .date_wrap .date span {
        margin-left: 0;
    }

    .search .category_wrap p {
        font-size: 0.75rem;
    }
    .search .align_tab ul li {
        font-size: 0.75rem;
    }
    .search .category_wrap label input {
        font-size: 0.75rem;
    }
    .search .category_wrap span {
        margin: 0 0.5rem;
    }
    .search .search-wrap label {
        margin-right: 0.5rem;
    }
    .search .search-wrap label p, .search .search-wrap select {
        font-size: 0.625rem;
    }
    .search .search-wrap select {
        padding-right: 0.75rem;
    }
    .search .search-btn button {
        padding: 0.625rem 1.25rem;
    }
}