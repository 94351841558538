/* 나중에 삭제 */
/* body > iframe {
    display: none;
} */

.roboimage_style_guide {
    font-family: "Noto Sans KR";
}
/* 스타일 가이드 버튼 - 민트 */
.roboimage_style_guide .hover_mint {
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    transition: 0.2s transform ease-in-out;
    background-color: #00E2E6;
    color: #202020;
}
.roboimage_style_guide .hover_mint::after {
    background-color: #00D3E0;
    border-radius: 100px;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    transform: translate(-100%,0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-in-out;
}
.roboimage_style_guide .hover_mint.disabled {
    background-color: #DFFAFA;
    color: #B7BDC3;
    cursor: default;
}
@media (hover: hover) and (pointer: fine) {
    .roboimage_style_guide .hover_mint:hover {
        transform: translateZ(0);
        backface-visibility: hidden;
        will-change: transform;
        border-color: transparent;
    }
    .roboimage_style_guide .hover_mint:hover::after {
        transform: translate(0,0);
    }
    .roboimage_style_guide .hover_mint.disabled:hover::after {
        transform: translate(-100%,0);
    }
}

/* 스타일 가이드 애니메이션 - 줌인 */
.roboimage_style_guide .zoom_in {
    animation: zoom_in 0.2s 1 both;
}
@keyframes zoom_in {
    0% { transform: scale(0.5); }
    100% { transform: scale(1); }
}

/* 스타일 가이드 텍스트 에리어 */
.roboimage_style_guide textarea:focus {
    border-color: #00E2E6;
}
@media (hover: hover) and (pointer: fine) {
    .roboimage_style_guide textarea:hover {
        border-color: #515151;
    }
    .roboimage_style_guide textarea:focus:hover {
        border-color: #00E2E6;
    }
}
