/* 파일명 규칙 설정 */
.container_file_name_setting {
    background-color: #F0F5FA;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    overflow: auto;
}
.wrap_file_name_setting {
    min-width: 300px;
    margin: 185px 30px auto 260px;
    position: relative;
}
@media screen and (max-width:800px){
    .wrap_file_name_setting {
        margin: 165px 30px auto 30px !important;
    }
}
@media screen and (max-width:541px){
    .wrap_file_name_setting {
        margin: 190px 30px auto 30px !important;
    }
}
.section_file_name_setting_yn {
    background-color: #FFF;
    border-radius: 13px;
    width: 100%;
    padding: 35px 40px 10px;
}
.section_file_name_setting_yn h3 {
    font-size: 18px;
    font-weight: 600;
    color: #202020;
}
.section_file_name_setting_yn p {
    font-size: 14px;
}
.section_file_name_setting_yn span {
    line-height: 32px !important;
}
.section_file_name_setting_yn span:nth-child(2n+1) {
    font-size: 13px !important;
}
.section_file_name_setting_yn span:nth-child(2n) {
    font-size: 12px !important;
    color: #878B90;
}
.section_file_name_setting {
    background: #FFF;
    width: 100%;
    margin: -20px auto 30px;
    border-radius: 13px;
    padding: 20px 40px;
}
.section_file_name_setting h3 {
    font-size: 18px;
    font-weight: 600;
    color: #3A3A3A;
}
.section_file_name_setting p {
    color: #878B90;
    font-size: 13px;
}
.section_file_name_setting_btn {
    position: relative;
    text-align: left;
    width: 100%;
    height: 150px;
    margin: 30px auto;
}
.btn_prev_file_name_setting {
    width: 158px;
    height: 40px;
    background-color: #FFF;
    border: 1px solid #ACACAC;
    border-radius: 50px;
    font-size: 15px;
}
.btn_save_file_name_setting {
    width: 158px;
    height: 40px;
    background-color: #00E2E6;
    border-radius: 50px;
    margin-left: 11px;
    font-size: 15px;
}
@media screen and (max-width:800px) {
    .section_file_name_setting_btn {
        text-align: center;
        height: 300px;
    }
    .btn_prev_file_name_setting, .btn_save_file_name_setting {
        width: 48%;
        height: 38px;
    }
}
.btn_close_file_name_setting {
    position: absolute;
    top: 16px;
    right: 20px;
}
.rules_card {
    position: relative;
    display: inline-block;
    width: 240px;
    background-color: #F7F7F7;
    padding: 25px 20px 20px;
    border-radius: 13px;
    margin: 10px 25px 20px 0;
    vertical-align: text-top;
}
.rules_card h5 {
    font-size: 14px;
}
.select_box_rules {
    background-color: #FFF !important;
    margin-bottom: 10px !important;
}
.btn_close_rules_card {
    position: absolute;
    top: 6px;
    right: 11px;
}
.btn_add_rules {
    width: 120px;
    height: 38px;
    background-color: #FFF;
    border: 1px solid #ACACAC;
    border-radius: 50px;
    font-size: 14px;
}
.bg_add_rules {
    text-align: center;
}
.select_area, .select_option {
    font-size: 13px !important;
}


/* 달력 */
.react-datepicker-wrapper {
    width: 100%;
    background-color: #FFF;
    margin-bottom: 10px;
}
.file_name_datepicker {
    width: 100%;
    height: 36px;
    padding-left: 15px !important;
    cursor: pointer;
    caret-color: #FFF;
    font-size: 13px !important;
}
.icn_datepicker {
    position: absolute;
    top: 4px;
    right: 8px;
}
.container_datepicker {
    position: relative;
}
/* 달력 끝 */

.input_file_name_rules {
    font-size: 13px !important;
    padding-left: 15px !important;
}

.file_name_setting_w {
    margin-top: 20px;
}
.notice_file_name_setting {
    font-size: 13px !important;
    color: #00E2E6;
}

@media screen and (max-width: 675px){
    .rules_card {
        display: block;
        margin: 30px auto;
    }
    .section_file_name_setting {
        padding: 20px;
    }
}
.radio_btn_file_name_setting {
    display: grid;
    grid-template-columns: 20px 100px 1fr;
}
.radio_btn_file_name_setting label {
    vertical-align: middle;
}