@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en");
/**
 *   INSPINIA - Responsive Admin Theme
 *   version 2.2
 */

.commonmenu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: right;
}
.commonmenu ul li {
    display: inline-block;
    position: relative;
    padding: 0px 20px;
}
.commonmenu ul li a {
    display: block;
    color: #222;
    /* font-weight: 700; */
    font-size: 14px;
    text-transform: uppercase;
    /* padding: 0px 20px; */
    transition: .3s;
    -webkit-transition: all 0.3s ease 0s;
}
.commonmenu ul li a:before {
    position: absolute;
    bottom: 0;
    content: "";
    /* width: 100%;
    height: 3px;
    background: #ffc527; */
    left: 0;
    opacity: 0;
    transition: .3s;
    -webkit-transition: all 0.3s ease 0s;
    visibility: hidden;
}
/* .commonmenu ul li:hover > a:before, .commonmenu ul li.active > a:before { */
.commonmenu ul li.active > a:before {
    opacity: 1;
    visibility: visible;
}
/* .commonmenu ul li:hover > a, .commonmenu ul li.active > a { */
.commonmenu ul li.active > a {
    /* background: #f4f4f4; */
    text-decoration: none;
    /* color: #ffc527; */
}
.commonmenu li ul {
    background: #fff none repeat scroll 0 0;
    right: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: absolute;
    text-align: left;
    margin-top: 8px;
    /* top: 108px; */
    top: 30px;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    visibility: hidden;
    width: auto;
    z-index: -1;
    white-space: nowrap;
    box-shadow: 0px 15px 30px rgb(0 0 0 / 10%);
    border-radius: 10px;
    padding: 10px;
}
.commonmenu li ul li {
    display: block;
}
.commonmenu li ul li a {
    padding-bottom: 5px;
    padding-top: 5px;
    text-transform: none;
}
/* .commonmenu li:hover ul { */
.commonmenu li.active ul {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    z-index: 99;
    top: 20px;
}

.commonmenu ul li p.active {
    color: #3A3A3A !important;
}

.green-dot {
    background: url("../resources/img/main/icn_greenDot.svg") no-repeat;
    background-size: 8px 8px;
    width: 8px;
    height: 8px;
    background-position: center;
}

.select-arrow-down {
    background: url("../resources/img/main/icn_selectArrowDown.svg") no-repeat;
    background-size: 11px 6px;
    width: 11px;
    height: 6px;
    background-position: center;
}

.arrow-up {
    background: url("../resources/img/main/icn_arrowUp.svg") no-repeat;
    background-size: 11px 6px;
    width: 11px;
    height: 6px;
    background-position: center;
}

.arrow-down {
    background: url("../resources/img/main/icn_arrowDown.svg") no-repeat;
    background-size: 11px 6px;
    width: 11px;
    height: 6px;
    background-position: center;
}

.sync .commonUserShopSyncState {
    background: url("../resources/img/main/icn_loading.svg") no-repeat;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    background-position: center;
}

.commonBoardMenu {
    background: url("../resources/img/main/icn_board.svg") no-repeat;
    background-size: 16px 4px;
    width: 16px;
    height: 4px;
    background-position: center;
}

.li_commonBoardMenu.active .commonBoardMenu {
    background: url("../resources/img/main/icn_board_active.svg") no-repeat;
    background-size: 16px 4px;
    width: 16px;
    height: 4px;
    background-position: center;
}

.sync a {
    color: #10CFC9 !important;
}

.commonAlarm {
    background: url("../resources/img/main/icn_alarm.svg") no-repeat;
    background-size: 22px 25px;
    width: 22px;
    height: 25px;
    background-position: center;
}

/* ------------------------------------------------------------ */
.mainmenu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: right;
}
.mainmenu ul li {
    display: inline-block;
    position: relative;
}
.mainmenu ul li a {
    display: block;
    color: #222;
    /* font-weight: 700; */
    font-size: 14px;
    /* text-transform: uppercase; */
    /* padding: 0px 20px; */
    /* transition: .3s; */
    /* -webkit-transition: all 0.3s ease 0s; */
}
.mainmenu ul li a:before {
    position: absolute;
    bottom: 0;
    content: "";
    width: 100%;
    height: 3px;
    background: #ffc527;
    left: 0;
    opacity: 0;
    transition: .3s;
    -webkit-transition: all 0.3s ease 0s;
    visibility: hidden;
}
/* .mainmenu ul li:hover > a:before, .mainmenu ul li.active > a:before { */
.mainmenu ul li.active > a:before {
    opacity: 1;
    /* visibility: visible; */
}
/* .mainmenu ul li:hover > a, .mainmenu ul li.active > a { */
.mainmenu ul li.active > a {
    background: #f4f4f4;
    text-decoration: none;
    color: #ffc527;
}

.mainmenu ul li.active ul {
    /* height: auto; */
    max-height: 100%;
    -webkit-transition: max-height 0.5s ease-in-out;
    -moz-transition: max-height 0.5s ease-in-out;
    -ms-transition: max-height 0.5s ease-in-out;
    -o-transition: max-height 0.5s ease-in-out;
    transition: max-height 0.5s ease-in-out;
    /* display: block; */
}

.mainmenu li ul {
    background: #fff none repeat scroll 0 0;
    left: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    /* position: absolute; */
    text-align: left;
    top: 108px;
    /* -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; */
    visibility: visible;
    /* visibility: hidden; */
    width: 200px;
    z-index: -1;
    /* display: none;  */
    height: auto;
    max-height: 0;
    overflow: hidden;
}
.mainmenu li ul li {
    display: block;
}
.mainmenu li ul li a {
    padding-bottom: 5px;
    padding-top: 5px;
    text-transform: none;
}
/* .mainmenu li:hover ul { */
.mainmenu li.active ul {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    z-index: 99;
    display: block;
    /* top: 30px; */
}

.background_white {
    background: #FFFFFF !important;
}

@media(max-width:800px) {
    .commonmenu li.active ul {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        visibility: visible;
        z-index: 99;
        top: 30px;
        left:0px !important;
        right: auto !important;
    }

    .commonmenu li ul {
        background: #fff none repeat scroll 0 0;
        top: 55px;
        left:0px !important;
        right: auto !important;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        position: absolute;
        text-align: left;
        margin-top: 8px;
        /* top: 108px; */
        -webkit-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        visibility: hidden;
        width: auto;
        z-index: -1;
        white-space: nowrap;
        box-shadow: 0px 15px 30px rgb(0 0 0 / 10%);
        border-radius: 10px;
        padding: 10px;
    }

    .mainmenu .hover_menu {
        border-top: 0px !important;
        padding: 10px 18px !important;
        box-shadow: none !important;
    }

    .mainmenu .hover_menu a {
        padding: 8px 42px !important;
    }

}