:global .robo_img_setting_popup .common_popup_frame{
    align-items: center;
    position: fixed;
}
:global .robo_img_setting_popup .common_popup_frame_div{
    position : relative;
}
:global .robo_img_setting_popup .common_popup_frame_title_icon, :global .robo_img_setting_popup h3{
    padding: 0px 12px;
}

@media (max-height:1000px) {
    :global .robo_img_setting_popup .common_popup_frame{
        align-items: baseline;
    }
}

.robo_img_setting_popup textarea, .robo_img_setting_popup label, .robo_img_setting_popup input, .robo_img_setting_popup div, .robo_img_setting_popup button{
    font-family: "Noto Sans KR" !important;
}
.robo_img_setting_popup textarea::placeholder{
    color: #878B90 !important;
}

.robo_img_popup{
    padding: 0px 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.input_prompt{
    width: 100%;
    position: relative;
    height: 160px;
    background: #FFFFFF;
    border: 1px solid #D1D1D1;
    border-radius: 8px;
    padding-top: 12px;
}
.input_prompt textarea{
    width: 100%;
    height: 80px;
    border: none;
    font-weight: 400;
    font-size: 13px;
    padding: 0px 16px;
    word-break: keep-all;
    resize: none;
    border-radius: 8px;
    line-height: 19.5px;
}
.input_prompt textarea:focus::placeholder{
    color: transparent !important;
}
.input_prompt_info{
    font-family: "Noto Sans KR";
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color : #9FA4AA;
    margin-left: 16px;
    margin-top: 4px;
}
.hover_dark, .input_prompt .new_prompt_btn{
    position: absolute !important;
    right: 16px;
    bottom: 19px;
    width: 137px !important;
    font-size: 13px;
    border-radius: 50px !important;
    height: 33px;
    font-weight: 400;
}
.new_prompt_btn{
    line-height: 2;
}
.new_prompt_btn img{
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-bottom: 2px;
}
.content_title{
    margin-top: 24px;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
}
.content_area{
    display: flex;
    margin-top: 8px;
    justify-content: space-between;
    align-items: center;
    width: calc(100% + 8px);
    align-self: center;
}
.content_area > div{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 128px;
}
.content .img_area{
    width: 120px;
    height: 120px;
    border-radius: 12px;
    object-fit: cover;
    -webkit-user-drag: none;
    cursor: pointer;
    box-shadow: 0px 4px 14px 0px #00000017 !important;
}
.content .img_area{
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
}
.content label{
    position: absolute;
    /* bottom: 8px; */
    bottom: 10px;
    width: 120px;
    text-align: center;
    font-size: 13px;
    line-height: 13px;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 0px;
    cursor: pointer;
    z-index: 1;
}
.color_gray{
    color: #63666A !important;
}
.select_content{
    justify-content: center !important;
}
.select_content .img_area{
    border: 4px solid #00E2E6;
    width: 128px;
    height: 128px;
}
.select_content label{
    width: 100%;
    /* bottom: 12px; */
    bottom: 14px;
}
.make_btn{
    width: 100%;
    align-self: center;
    margin-top: 32px;
}
.make_btn button{
    width: 100%;
    height: 40px;
    font-size: 15px;
    border-radius: 50px !important;
}

.img_popup_title{
    width: 100%;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}
.robo_img{
    max-width: 440px;
    max-height: 600px;
    align-self: center;
    -webkit-user-drag: none;
}
.robo_img_button_area{
    width: 440px;
    align-self: center;
    display: flex;
    margin-top: 30px;
}
.robo_img_button_area button{
    width: 100%;
    height: 46px;
    font-weight: 400;
    font-size: 15px;
    border-radius: 50px !important;
}
.robo_img_button_area button:nth-of-type(1){
    margin-right: 8px;
}
.robo_img_button_area button:nth-of-type(2){
    margin-left: 8px;
}
@media (max-width:800px) {
    .img_popup_title{
        font-size: 16px;
    }
    .robo_img{
        max-width: 100%;
        padding: 0px 16px;
    }
    .robo_img_button_area{
        width: 100%;
    }
    .robo_img_button_area button{
        height: 44px;
        font-size: 14px;
    }
    .robo_img_button_area button:nth-of-type(1){
        margin-left: 16px;
    }
    .robo_img_button_area button:nth-of-type(2){
        margin-right: 16px;
    }
}
@media (max-width:470px) {
    .content label{
        bottom: 6px;
        font-size: 11px;
    }
    .content_area > div{
        width: 88px;
    }
    .content_area .img_area{
        width: 80px;
        height: 80px;
    }
    .select_content .img_area{
        width: 88px !important;
        height: 88px !important;
    }
    .select_content label{
        bottom: 10px !important;
    }
    .robo_img_button_area button{
        /* width: 140px; */
        height: 40px;
        margin: 0px 10px;
        font-size: 13px;
    }
}