/* Setting */
html {
    font-size: 16px;
}
label {
    margin-bottom: 0;
}
body {
    font-family: "Noto Sans 400";
    font-size: 1rem;
    color: #333;
}
button:focus {
    outline: none;
    cursor: pointer;
}
select:focus {
    outline: none;
}
input:focus {
    outline: none;
}

/* a:hover {
    text-decoration: none;
} */

h1, h2, h3, h4, h5, p, ul {
    margin-bottom: 0;
}
.bg-gr {
    background: #e5e5e5;
}
header {
    width: 100%;
    height: 90px;
    top: 0px;
    background: rgb(255, 255, 255);
    padding: 32px 2.5% 32px 25px;
    position: fixed;
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 15px;
    z-index: 2041;
}
.menu_area {
    z-index: 2040;
    position: fixed;
    top: 90px;
    width: 220px;
    height: 100%;
    border: 0;
    background: #fff;
}
.contents_area {
    max-width: 95%;
    width: 100%;
    min-height: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 8.125rem 0 8.125rem 13.75rem;
}
.content_title {
    margin-bottom: 3.125rem;
}
.content_title h1 {
    font-family: "Noto Sans 700";
    font-size: 1.25rem;
    line-height: 1.688rem;
}

.content_area {
    width: 75rem;
    margin: 0 auto;
}
/* 날짜선택 */
.content_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 6.25rem;
    padding: 0 3.125rem;
    border-radius: 10px;
    background: #fff;
}

.date_wrap {
    display: flex;
    align-items: center;
}
.date_wrap .date_input{ 
    display: flex;
    align-items: center;
}
.date_wrap .date {
    display: flex;
    margin: 0 1.875rem;
}
.date_wrap .date p {
    font-family: "Noto Sans 700";
    font-size: 1.0625rem;
    line-height: 1.446875rem;
}
.date_wrap .date span {
    font-size: 1.0625rem;
    line-height: 1.446875rem;
    margin-left: 1rem;
}

.date_wrap .date_input .date_prev {
    width: 8px;
    height: 14px;
    background: url(../img/icon/ico_left_arrow_gr.png)no-repeat;
    border: none;
}
.date_wrap .date_input .date_next {
    width: 8px;
    height: 14px;
    background: url(../img/icon/ico_right_arrow_gr.png)no-repeat;
    border: none;
}

.content_header select {
    /* font-family: "Noto Sans"; */
    font-weight: 500;
    padding: 0 1.5rem 0 1.25rem;
    margin-left: 1.875rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    background: url(../img/icon/ico_down_arrow_mt.png)no-repeat;
    background-position: right center;
}

.content_tabs ul {
    display: flex;
    align-items: center;
}
.content_tabs ul li {
    margin-left: 1.25rem;
}
.content_tabs ul li a {
    line-height: 1.6875rem;
    font-size: 1.125rem;
    color: #333;
    opacity: 30%;
}
.content_tabs ul li.active a {
    /* font-family: "Noto Sans 700"; */
    font-weight: 500;
    opacity: 1;
}


/* 컨텐츠 */
.content_item {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin-top: 1.25rem;
}
.content_item .content {
    width: 50%;
    padding: 3.125rem;
    border-radius: 0.625rem;
    background: #fff;
}
.content_item .content:last-child {
    margin-left: 1.125rem;
}
.content_item .content .title h1 {
    font-family: "Noto Sans 700";
    font-size: 1.25rem;
    line-height: 1.7025rem;
    letter-spacing: -1.5%;
    color: #333;
}

.content_item  .content .title h1 span {
    color: #10CFC9;
}
.content_item .content .total p {
    font-size: 1.125rem;
    line-height: 1.5325rem;
    margin-top: 0.6875rem;
    margin-bottom: 3.125rem;
    color: #333;
    opacity: 0.3;
}
.content_item ul li {
    display: flex;
    align-items: center;
    margin-bottom: 1.875rem;
}
.content_item ul li:last-child {
    margin-bottom: 0;
}
.content_item ul li p {
    display: block;
    width: 4.625rem;
    font-size: 1.25rem;
    line-height: 1.7025rem;
    color: #333333;
}
.content_item ul li.imp p {
    font-family: "Noto Sans 700";
    color: #10CFC9;
}
.content_item ul li span {
    font-size: 1.125rem;
    line-height: 1.5325rem;
    letter-spacing: -1.5%;
    color: #333;
    opacity: 0.3;
}
.content_item ul li.imp span {
    opacity: 1;
}
.content_item .progress {
    max-width: 18.125rem;
    width: 100%;
    height: 1.25rem;
    border-radius: 2.375rem;
}
  
.content_item .progress-wrap {
    position: relative;
    margin: 0 1.875rem 0 4.875rem;
    background: rgba(192, 192, 192, 0.3);
    overflow: hidden;
}
.content_item .content:last-child .progress-wrap {
    position: relative;
    background: rgba(192, 192, 192, 0.3);
    overflow: hidden;
}
.content_item .content:last-child .title {
    display: flex;
    justify-content: space-between;
}
.content_item .content:last-child .title button {
    width: 1.25rem;
    height: 1.25rem;
    background: url(../img/icon/ico_close.png) no-repeat;
    border: none;
}
.content_item .progress-wrap .progress-bar {
    background: #10CFC9;
    position: absolute;
    left: -100%;
    top: 0;
}

.rank .content_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rank .content_title .help p {
    font-family: "Noto Sans 700";
    position: relative;
    font-size: 0.8125rem;
    color: #10CFC9;
}
.rank .content_title .help i {
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    text-align: center;
    font-family: "Noto Sans 400";
    color: #fff;
    margin-right: 0.625rem;
    border-radius: 100%;
    background: #10CFC9;
}
.rank .content_title .help span {
    font-family: "Noto Sans 400";
    color: #333;
    margin-left: 1rem;
}

.rank .result_wrap {
    display: flex;
    align-items: center;
    width: 100%;
    height: 6.25rem;
    margin-top: 1.25rem;
    padding-left: 3.125rem;
    border-radius: 0.625rem;
    background: #fff;
}
.rank .result_wrap p {
    font-family: "Noto Sans 700";
    font-size: 1.125rem;
}
.rank .result_wrap img {
    margin-right: 1rem;
}
.rank .result_wrap span {
    color: #10CFC9;
}
.rank .result_wrap em {
    margin: 0 0.5rem;
}

.rank .content_item {
    flex-direction: column;
}
.rank .content_item .content {
    width: 100%;
}
.rank .content_item .content:last-child {
    margin-top: 1.25rem;
    margin-left: 0;
}

.rank .content_item .content > h1 {
    font-family: "Noto Sans 700";
    font-size: 1.25rem;
    margin-bottom: 3.125rem;
}
.rank .content_item .content ul {
    display: flex;
    flex-wrap: wrap;
}
.rank .content_item .content ul li {
    align-items: baseline;
    flex-direction: column;
    max-width: 12.25rem;
    width: 100%;
    margin-right: 1.875rem;
    margin-bottom: 3.75rem;
}
.rank .content_item .content ul li:nth-child(5n) {
    margin-right: 0;
}

.rank .content_item .img-area {
    position: relative;
    width: 100%;
    height: 15.625rem;
    border-radius: 0.625rem;
    background: #c4c4c4;
    overflow: hidden;
}
.rank .content_item .content .icon_wrap {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    align-items: center;
}
.rank .content_item .content .ico:last-child {
    margin-left: 5px;
}
.rank .content_item .content .ico {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.6875rem;
    height: 1.6875rem;
    border-radius: 5px;
    background: #fff;
}

.rank .content_item .content .ico img {
    width: 60%;
}
.rank .content_item .content .ico p {
    display: contents;
    width: inherit;
    line-height: 1.6875rem;
    font-size: 0.9375rem;
    font-family: "Noto Sans 700";
}

.rank .content_item .content li.green .ico {
    background: #10CFC9;
}
.rank .content_item .content li.green .ico p {
    color: #fff;
}
.rank .content_item .content li.pink .ico {
    background: #FFAEC2;
}
.rank .content_item .content li.pink .ico p {
    color: #fff;
}
.rank .content_item .content .text-area h3 {
    font-family: "Noto Sans 400";
    font-size: 0.9375rem;
    margin: 1.875rem 0 0.625rem;
}

.rank .content_item .content .price {
    display: flex;
    align-items: flex-end;
}
.rank .content_item .content .price p {
    width: inherit;
    line-height: inherit;
    font-size: 0.9375rem;
    font-family: "Noto Sans 700";
}
.rank .content_item .content .price del {
    display: inline;
    font-family: "Noto Sans 400";
    font-size: 0.8125rem;
    color: #bbbbbc;
    margin: 0 0.8125rem;
}
.rank .content_item .content .price span {
    font-family: "Noto Sans 700";
    font-size: 0.9375rem;
    color: #f3557c;
    opacity: 1;
}

/* 검색결과페이지 */
.search .align_tab ul {
    display: flex;
    align-items: center;
}
.search .align_tab ul li {
    font-size: 1.125rem;
    margin-right: 1.25rem;
    opacity: 0.2;
}
.search .align_tab ul li:last-child {
    margin-right: 0;
}
.search .align_tab ul li.active {
    font-family: "Noto Sans 700";
    opacity: 1;
}

.search .category_wrap {
    display: flex;
    align-items: center;
}
.search .category_wrap p {
    font-size: 1.0625rem;
    margin-right: 1.875rem;
}
.search .category_wrap span {
    margin: 0 1.0625rem;
}
.search .category_wrap label {
    position: relative;
    display: flex;
    align-items: center;
    width: 2rem;
    margin-bottom: 0;
}

.search .category_wrap input {
    width: 100%;
    font-family: "Noto Sans 700";
    border: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.search .category_wrap i {
    display: inline-block;
    vertical-align: middle;
    width: 29px;
    height: 29px;
    background: url(../img/icon/calender.png)no-repeat;
    background-position: center;
    background-size: 100%;
}

.search .search-wrap {
    margin-top: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 6.25rem;
    padding: 0 3.125rem;
    border-radius: 10px;
    background: #fff;
}
.search .search-wrap .search-area input {
    font-size: 1.125rem;
    width: 15.5rem;
    font-size: 1.125rem;
    padding-left: 2rem;
    border: none;
    background: url(../img/icon/search.png)no-repeat;
}
.search .search-wrap .search-area input::placeholder {
    font-size: 1.125rem;
    color: #333;
    opacity: .2;
}

.search .search-wrap .select-wrap {
    display: flex;
}
.search .search-wrap label {
    display: flex;
    align-items: center;
    margin-right: 3.125rem;
}
.search .search-wrap label:last-child {
    margin-right: 0;
}
.search .search-wrap label p {
    font-size: 0.9375rem;
}
.search .search-wrap select {
    font-family: "Noto Sans 700";
    padding: 0 1.5rem 0 0; 
    margin-left: 1.5625rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: 0.9375rem;
    border: none;
    background: url(../img/icon/ico_down_arrow_mt.png)no-repeat;
    background-position: right center;
}

.search .search-wrap input {
    font-size: 1.125rem;
    width: 5.0rem;
    font-size: 1.125rem;
    border: none;
}

.search .search-wrap input::placeholder {
    font-size: 1.125rem;
    color: #333;
    opacity: .2;
}

.search .search-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.25rem;
}
.search .search-btn button {
    font-size: 0.9375rem;
    font-family: "Noto Sans 700";
    padding: 0.9375rem 1.875rem;
    color: #fff;
    border-radius: 8px;
    border: none;
    background: #10cfc9;
}

.search .no-search {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 35.4375rem;
    border-radius: 8px;
    background: #fff;
}

.date .react-datepicker__navigation{
    top: 13px;
}
.fb_from_date_no_border {
    border:0px !important;
    font-weight: 500;
    font-size: 1.0625rem !important;
    height: auto;
    width:auto;
    max-width: 160px;
    /* font-family: sans-serif; */
}

.font_family_normal {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
}