.roboimage_popup * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.roboimage_popup {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    background-color: #00000073;
    overflow: auto;
    z-index: 20;
}
.roboimage_popup::-webkit-scrollbar {
    display: none;
}
.content_wrap {
    width: 100%;
    height: max-content;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.content {
    width: 568px;
    margin-top: 119px;
    margin-bottom: 40px;
    padding-top: 46px;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #FFF;
    color: #202020;
    text-align: center;
    overflow: hidden;
}
.content > svg {
    width: 24px;
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
}
.content h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    padding: 0 36px;
}
.content h2 .mobile_br {
    display: none;
}
.content h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    padding: 0 36px;
    margin-top: 12px;
    color: #3A3A3A;
    white-space: pre-line;
}
.content .blurred_img {
    width: 328px;
    height: 328px;
    position: relative;
    margin-top: 20px;
    cursor: pointer;
}
.content .blurred_img div {
    width: 100%;
    padding: 20px;
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    color: #FFF;
}
.content .blurred_img img {
    width: 100%;
}
.content .coupon {
    width: 328px;
    height: 202px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 6px;
    margin-top: 30px;
    background-color: #EEE;
}
.content .coupon h4 {
    width: auto;
    height: 22px;
    padding: 5px 10px;
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
    border: 1px solid #D1D1D1;
    background-color: #FFF;
    color: #3A3A3A;
}
.content .coupon h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-top: 34px;
}
.content .coupon img {
    width: 182px;
    margin-top: 12px;
}
.content .register_btn {
    width: 100%;
    height: 56px;
    background-color: #00E2E6;
    margin-top: 46px;
    transition: 0.2s;
}
@media (hover: hover) and (pointer: fine) {
    .content .register_btn:hover {
        background-color: #00D3E0;
    }
}
@media screen and (max-width: 720px) {
    .content {
        width: 100%;
        min-height: 100vh;
        margin: 0;
        padding-top: 36px;
        padding-bottom: 88px;
        border-radius: 0;
    }
    .content h2 {
        font-size: 18px;
        line-height: 24px;
        padding: 0 16px;
    }
    .content h2 .mobile_br {
        display: block;
    }
    .content h3 {
        font-size: 13px;
        line-height: 22px;
        padding: 0 16px;
        margin-top: 6px;
    }
    .content .blurred_img div {
        font-size: 18px;
    }
    .content .coupon {
        margin-top: 26px;
    }
    .register_btn {
        position: absolute;
        left: 0;
        bottom: 0;
        margin-top: 0;
    }
}