.roboimage_result * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.roboimage_result {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 170px;
    padding-bottom: 40px;
    box-sizing: border-box;
    color: #202020;
}
.roboimage_result h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
}
.roboimage_result h2 .mobile_br {
    display: none;
}
.roboimage_result img {
    width: 360px;
    margin-top: 40px;
}
.roboimage_result button {
    width: 360px;
    height: 46px;
    font-size: 15px;
    line-height: 22px;
    margin-top: 56px;
}
@media screen and (max-width: 720px) {
    .roboimage_result {
        padding-top: 32px;
        padding-bottom: 47px;
    }
    .roboimage_result h2 {
        font-size: 18px;
        line-height: 25px;
    }
    .roboimage_result h2 .mobile_br {
        display: block;
    }
    .roboimage_result img {
        width: 328px;
        margin-top: 32px;
    }
    .roboimage_result button {
        width: 328px;
        margin-top: 40px;
    }
}