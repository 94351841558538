/* 기본 page-wrapper */
#common-page-wrapper {
    max-width: 100%;
    background-color: #ffffff;
    min-height: 100%;
    max-width: 100%;
}
#non-common-page-wrapper {
    margin: 0 calc(3% + 15px) 0;
}
@media ( min-width : 801px ) {
    #common-page-wrapper {
        padding: 71px 0 0 228px;
    }
}
@media ( max-width : 800px ) {
    #common-page-wrapper {
        /* padding: 70px 0px 0 0px !important; */
        padding: 60px 0px 72px 0px !important;
    }
}

/* 기본 pagination */
.common_pagination .pagination {
    width: 100%;
    max-width: 400px;
    margin: auto;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
}
.common_pagination .pagination li{
    margin: 0;
}
.common_pagination .pagination li a{
    min-width: 16px;
    color: #63666a !important;
    font-size: 13px;
    display: flex;
    font-weight: inherit;
    display: block;
    text-align: center;
    margin: auto 8px;
}
.common_pagination .pagination .active a{
    color: #00e2e6 !important;
}

/* rc-slider */
.rc-slider-track{
    background-color: #73ebec !important;
}
.rc-slider-dot-active{
    border-color : #00e2e6 !important;
}
.rc-slider-handle{
    border: solid 2px #00e2e6 !important;
    opacity: 1;
}
.rc-slider-handle:active {
    border-color: #00e2e6 !important;
    box-shadow: 0 0 5px #00e2e6 !important;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging{
    box-shadow: 0 0 0 5px #73ebec !important;
}

/* react-select */
.react-select [class*='control'] input{
    border: 0px !important;
    padding: 0px !important;
    font-size: 14px !important;
    height: auto;
}
.react-select [class*='control'] input:focus:hover{
    border: 0px !important;
}
.react-select [class*='control'] input:focus{
    border: 0px !important;
}
.react-select.error [class*='control']{
    border: 1px solid #FF085E;
}
.react-select [class*='indicatorSeparator']{
    display: none;
}
.react-select [class*='indicatorContainer']{
    background: url("data:image/svg+xml;utf8,%3Csvg width='20' height='20' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6751 12.325C12.6985 12.3477 12.7171 12.3749 12.7298 12.405C12.7426 12.4351 12.7491 12.4674 12.7491 12.5C12.7491 12.5326 12.7426 12.565 12.7298 12.595C12.7171 12.6251 12.6985 12.6523 12.6751 12.675C12.6523 12.6984 12.6251 12.7171 12.5951 12.7298C12.565 12.7425 12.5327 12.7491 12.5001 12.7491C12.4674 12.7491 12.4351 12.7425 12.405 12.7298C12.375 12.7171 12.3478 12.6984 12.3251 12.675L8.00005 8.35626L3.67505 12.675C3.65233 12.6984 3.62513 12.7171 3.59507 12.7298C3.56501 12.7425 3.5327 12.7491 3.50005 12.7491C3.46741 12.7491 3.4351 12.7425 3.40504 12.7298C3.37498 12.7171 3.34778 12.6984 3.32505 12.675C3.30162 12.6523 3.28298 12.6251 3.27026 12.595C3.25753 12.565 3.25098 12.5326 3.25098 12.5C3.25098 12.4674 3.25753 12.4351 3.27026 12.405C3.28298 12.3749 3.30162 12.3477 3.32505 12.325L7.6438 8.00001L3.32505 3.67501C3.28895 3.62686 3.27142 3.56731 3.27568 3.50728C3.27995 3.44726 3.30572 3.39078 3.34828 3.34823C3.39083 3.30568 3.4473 3.2799 3.50733 3.27564C3.56736 3.27137 3.62691 3.2889 3.67505 3.32501L8.00005 7.64376L12.3251 3.32501C12.3465 3.29635 12.3739 3.27265 12.4054 3.25551C12.4368 3.23836 12.4716 3.22817 12.5073 3.22564C12.5431 3.2231 12.5789 3.22826 12.6125 3.24079C12.646 3.25331 12.6765 3.2729 12.7018 3.29823C12.7272 3.32356 12.7467 3.35403 12.7593 3.38759C12.7718 3.42114 12.777 3.457 12.7744 3.49273C12.7719 3.52846 12.7617 3.56322 12.7446 3.59467C12.7274 3.62612 12.7037 3.65352 12.6751 3.67501L8.3563 8.00001L12.6751 12.325Z' fill='%23333333'/%3E%3C/svg%3E%0A");
    padding: 10px;
    margin-right: 10px;
}
.react-select [class*='indicatorContainer']:last-child{
    background: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.4375 7.49884C3.43765 7.45796 3.44603 7.41752 3.46213 7.37994C3.47824 7.34236 3.50174 7.3084 3.53125 7.28009C3.55965 7.2508 3.59365 7.22751 3.63123 7.2116C3.66881 7.1957 3.7092 7.1875 3.75 7.1875C3.7908 7.1875 3.83119 7.1957 3.86877 7.2116C3.90635 7.22751 3.94035 7.2508 3.96875 7.28009L10 13.3035L16.0312 7.2801C16.0914 7.23496 16.1659 7.21305 16.2409 7.21838C16.3159 7.22371 16.3865 7.25593 16.4397 7.30912C16.4929 7.36232 16.5251 7.43291 16.5305 7.50794C16.5358 7.58298 16.5139 7.65742 16.4687 7.7176L10.2187 13.9676C10.1903 13.9969 10.1563 14.0202 10.1188 14.0361C10.0812 14.052 10.0408 14.0602 10 14.0602C9.95919 14.0602 9.91881 14.052 9.88123 14.0361C9.84365 14.0202 9.80965 13.9969 9.78125 13.9676L3.53125 7.7176C3.50174 7.68929 3.47824 7.65533 3.46213 7.61775C3.44603 7.58017 3.43765 7.53973 3.4375 7.49884Z' fill='%23333333'/%3E%3C/svg%3E%0A") no-repeat;
}
.react-select.disabled [class*='indicatorContainer']:last-child{
    background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.4375 7.49994C3.43765 7.45906 3.44603 7.41862 3.46213 7.38104C3.47824 7.34346 3.50174 7.3095 3.53125 7.28119C3.55965 7.2519 3.59365 7.22861 3.63123 7.2127C3.66881 7.19679 3.7092 7.1886 3.75 7.1886C3.7908 7.1886 3.83119 7.19679 3.86877 7.2127C3.90635 7.22861 3.94035 7.2519 3.96875 7.28119L10 13.3046L16.0312 7.28119C16.0914 7.23606 16.1659 7.21415 16.2409 7.21948C16.3159 7.22481 16.3865 7.25703 16.4397 7.31022C16.4929 7.36341 16.5251 7.43401 16.5305 7.50904C16.5358 7.58408 16.5139 7.65852 16.4687 7.71869L10.2187 13.9687C10.1903 13.998 10.1563 14.0213 10.1188 14.0372C10.0812 14.0531 10.0408 14.0613 10 14.0613C9.95919 14.0613 9.91881 14.0531 9.88123 14.0372C9.84365 14.0213 9.80965 13.998 9.78125 13.9687L3.53125 7.71869C3.50174 7.69039 3.47824 7.65643 3.46213 7.61885C3.44603 7.58127 3.43765 7.54083 3.4375 7.49994Z' fill='%23ACACAC'/%3E%3C/svg%3E%0A");
}
.react-select [class*='indicatorContainer'] svg{
    display: none;
}
.react-select [class*='multiValue'] div:last-of-type{
    background: url("data:image/svg+xml;utf8,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.6421 9.10963C9.71252 9.18073 9.75202 9.27675 9.75202 9.37682C9.75202 9.47688 9.71252 9.57291 9.6421 9.644C9.57042 9.71331 9.47462 9.75206 9.37491 9.75206C9.27521 9.75206 9.1794 9.71331 9.10773 9.644L5.99991 6.5315L2.8921 9.644C2.82042 9.71331 2.72462 9.75206 2.62491 9.75206C2.52521 9.75206 2.4294 9.71331 2.35772 9.644C2.28731 9.57291 2.2478 9.47688 2.2478 9.37682C2.2478 9.27675 2.28731 9.18073 2.35772 9.10963L5.47022 6.00182L2.35772 2.894C2.29793 2.82114 2.26737 2.72864 2.27199 2.6345C2.27661 2.54036 2.31609 2.4513 2.38274 2.38465C2.44939 2.318 2.53845 2.27852 2.6326 2.27389C2.72674 2.26927 2.81924 2.29983 2.8921 2.35963L5.99991 5.47213L9.10773 2.35963C9.18059 2.29983 9.27308 2.26927 9.36723 2.27389C9.46137 2.27852 9.55043 2.318 9.61708 2.38465C9.68373 2.4513 9.72321 2.54036 9.72783 2.6345C9.73246 2.72864 9.7019 2.82114 9.6421 2.894L6.5296 6.00182L9.6421 9.10963Z' fill='%23333333'/%3E%3C/svg%3E%0A");
    width: 12px;
    height: 12px;
    margin: 6px;
}
.react-select.disabled [class*='multiValue'] div:last-of-type{
    background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.64222 9.10963C9.71264 9.18073 9.75214 9.27675 9.75214 9.37682C9.75214 9.47688 9.71264 9.57291 9.64222 9.644C9.57054 9.71331 9.47474 9.75206 9.37503 9.75206C9.27533 9.75206 9.17952 9.71331 9.10785 9.644L6.00003 6.5315L2.89222 9.644C2.82054 9.71331 2.72474 9.75206 2.62503 9.75206C2.52533 9.75206 2.42952 9.71331 2.35785 9.644C2.28743 9.57291 2.24792 9.47688 2.24792 9.37682C2.24792 9.27675 2.28743 9.18073 2.35785 9.10963L5.47035 6.00182L2.35785 2.894C2.29805 2.82114 2.26749 2.72864 2.27211 2.6345C2.27674 2.54036 2.31621 2.4513 2.38286 2.38465C2.44951 2.318 2.53857 2.27852 2.63272 2.27389C2.72686 2.26927 2.81936 2.29983 2.89222 2.35963L6.00003 5.47213L9.10785 2.35963C9.18071 2.29983 9.27321 2.26927 9.36735 2.27389C9.4615 2.27852 9.55056 2.318 9.61721 2.38465C9.68386 2.4513 9.72333 2.54036 9.72796 2.6345C9.73258 2.72864 9.70202 2.82114 9.64222 2.894L6.52972 6.00182L9.64222 9.10963Z' fill='%238B8B8B'/%3E%3C/svg%3E%0A");
}
.react-select [class*='multiValue'] div:last-of-type svg{
    display: none;
}
.react-select [class*='control'] div > div:nth-child(7n+1) [class*='multiValue'] {
    background: #AEF3F2;
}
.react-select [class*='control'] div > div:nth-child(7n+2) [class*='multiValue'] {
    background: #B9DDFF;
}
.react-select [class*='control'] div > div:nth-child(7n+3) [class*='multiValue'] {
    background: #D5C2FD;
}
.react-select [class*='control'] div > div:nth-child(7n+4) [class*='multiValue'] {
    background: #FFBCD0;
}
.react-select [class*='control'] div > div:nth-child(7n+5) [class*='multiValue'] {
    background: #B5F4C7;
}
.react-select [class*='control'] div > div:nth-child(7n+6) [class*='multiValue'] {
    background: #CCD9E6;
}
.react-select [class*='control'] div > div:nth-child(7n+7) [class*='multiValue'] {
    background: #EEEEEE;
}

/* 기본 토글 버튼 */
.toggle_button{
}
.toggle_button label{
    margin-bottom: 0px;
}
.toggle_button_btn_1{
    width: 48px;
    height: 28px;
    border-radius: 15px;
    padding: 2px;
    box-sizing: border-box;
    background-color: #eee;
    transition: 0.2s;
    cursor: pointer;
}
.toggle_button_btn span{
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #fff;
    transition: 0.2s;
}
.toggle_button_id{
    display: none;
}
.toggle_button_id ~ label{
    display: flex;
    justify-content: center;
}
.toggle_button_id:checked ~ label .toggle_button_btn_1{
    background-color: #00e2e6;
}
.toggle_button_id:checked ~ label .toggle_button_btn_1 span{
    transform: translateX(85%);
}
.toggle_button.small .toggle_button_btn_1{
    width: 38px;
    height: 22px;
}
.toggle_button.small .toggle_button_btn span{
    width: 18px;
    height: 18px;
}
.toggle_button.small .toggle_button_id:checked ~ label .toggle_button_btn_1 span{
    transform: translateX(90%);
}

/* 공통 다이얼로그 */
.common_dialog{
    width: 100%;
    height: 100vh;
    padding: 40px;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}
/* 
    다이얼로그 - 선택(CTA 버튼 사용)
    (다음 단계로 넘어가기 위해 사용자의 선택이 필요한 경우 사용합니다. / CTA 버튼 사용)
*/
.dialog_select_CTA{
    width: auto;
    max-width: 95%;
    min-width: 328px;
    min-height: 234px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.05);
    position: fixed;
    z-index: 1000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 24px 16px;
    padding-bottom: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.dialog_select_CTA .dialog_close_btn{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 12px;
    top: 12px;
}
.dialog_select_CTA .dialog_select_CTA_txt{
    width: 100%;
    text-align: center;
}
.dialog_select_CTA .dialog_select_CTA_txt h2{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #202020;
    margin-bottom: 0px;
}
.dialog_select_CTA .dialog_select_CTA_txt h3{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 8px;
    color: #3a3a3a;
}
.dialog_select_CTA .dialog_select_CTA_btn_wrap{
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 16px;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.dialog_select_CTA .dialog_select_CTA_btn_wrap button{
    width: 148px;
    height: 42px;
    border-radius: 42px;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    transition: 0.2s;
}
.dialog_select_CTA .dialog_select_CTA_btn_wrap button:nth-of-type(1){
    color: #878b90;
}
@media (hover: hover) and (pointer: fine){
    .dialog_select_CTA .dialog_select_CTA_btn_wrap button:nth-of-type(1):hover{
        color: #3a3a3a;
    }
}
/* 
    다이얼로그 - 완료
    (사용자의 선택이 완료된 경우 사용합니다.)
*/
.dialog_complete{
    width: 328px;
    max-width: 95%;
    min-height: 234px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.05);
    position: fixed;
    z-index: 1000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 16px;
    padding-bottom: 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.dialog_complete .dialog_complete_content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.dialog_complete .dialog_complete_content svg{
    width: 48px;
    height: 48px;
    margin-bottom: 4px;
}
.dialog_complete .dialog_complete_content h2{
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #202020;
}
.dialog_complete button{
    width: 100%;
    height: 56px;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    color: #202020;
    background-color: #00e2e6;
    transition: 0.2s;
}
@media (hover: hover) and (pointer: fine){
    .dialog_complete button:hover{
        background-color: #00d3e0;
    }
}
/* 
    다이얼로그 - 입력
    (사용자가 직접 입력해야 할 정보가 있는 경우 사용합니다.)
*/
.dialog_insert{
    width: 328px;
    min-width: 328px;
    max-width: 95%;
    min-height: 234px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.05);
    position: fixed;
    z-index: 1000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0 16px;
    padding-bottom: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.dialog_insert_txt{
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #202020;
    word-break: break-all;
    padding: 40px 0px;
}
.dialog_insert_content{
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #3A3A3A;
}
.dialog_insert button{
    width: 100%;
    height: 56px;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    color: #202020;
    background-color: #00e2e6;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: 0.2s;
}
@media (hover: hover) and (pointer: fine){
    .dialog_insert button:hover{
        background-color: #00d3e0;
    }
}
.dialog_save_txt, .dialog_warning_txt {
    padding: 0;
}
.dialog_save_icn {
    margin-bottom: 5px;
}
.dialog_warning_icn {
    margin-bottom: 10px;
}

/* 
    다이얼로그 - 선택(CTA 버튼 비사용)
    (다음 단계로 넘어가기 위해 사용자의 선택이 필요한 경우 사용합니다. / CTA 버튼 비사용)
*/
.dialog_select{
    width: 328px;
    max-width: 95%;
    min-height: 216px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.05);
    position: fixed;
    z-index: 1000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 28px 16px;
    padding-bottom: 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.dialog_select .dialog_close_btn{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 12px;
    top: 10px;
}
.dialog_select .dialog_select_txt{
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    /* margin-bottom: 32px; */
    color: #202020;
    word-break: break-all;
    /* padding: 40px 0; */
}
.dialog_select .dialog_select_txt2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 8px;
    color: #3a3a3a;
    text-align: center;
}
.dialog_select .dialog_select_btn_wrap{
    width: 100%;
    border-top: 1px solid #eee;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.dialog_select .dialog_select_btn_wrap button{
    width: 50%;
    height: 56px;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    transition: 0.2s;
}
.dialog_select .dialog_select_btn_wrap button:nth-of-type(2){
    font-weight: 500;
}
.dialog_select .dialog_select_btn_wrap button:nth-of-type(1){
    color: #878b90;
}
.dialog_select .dialog_select_btn_wrap button:nth-of-type(2){
    color: #3a3a3a;
}
@media (hover: hover) and (pointer: fine){
    .dialog_select .dialog_select_btn_wrap button:nth-of-type(1):hover{
        background-color: #eee;
    }
    .dialog_select .dialog_select_btn_wrap button:nth-of-type(2):hover{
        background-color: #eee;
    }
}

/*
    다이얼로그 - 텍스트 입력(input)
    (input이 존재하는 다이얼로그 입니다.)
*/
.dialog_input{
    width: 270px;
    height: 40px;
    padding: 14px 16px;
    margin: 10px 0px 15px 0px;
}

/* 
    버튼 - 민트
*/
.hover_mint_dialog{
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    transition: 0.2s transform ease-in-out;
    background-color: #00e2e6;
    color: #202020;
}
.hover_mint_dialog::after{
    background-color: #00D3E0;
    border-radius: 100px;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    transform: translate(-100%,0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-in-out;
}
.hover_mint_dialog.disabled{
    background-color: #DFFAFA;
    color: #B7BDC3;
    cursor: default;
}
@media (hover: hover) and (pointer: fine){
    .hover_mint_dialog:hover{
        transform: translateZ(0);
        backface-visibility: hidden;
        will-change: transform;
        border-color: transparent;
    }
    .hover_mint_dialog:hover::after{
        transform: translate(0,0);
    }
    .hover_mint_dialog.disabled:hover::after{
        transform: translate(-100%,0);
    }
}

/* 막대 차트 */
.line_chart{
    width: 100%;
    height: 256px;
    position: relative;
}
.line_chart .line_chart_bar_wrap{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}
.line_chart_percent{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: 6px;
}
.line_chart_percent span{
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    color: #9fa4aa;
}
.line_chart_con{
    width: 420px;
    height: 100%;
    padding: 6px 0;
    position: relative;
}
.line_chart_con .chart{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    padding: 6px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}
.line_chart_con .chart .chart_bar{
    width: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: #3a3a3a;
    padding: 4px 0;
    border-radius: 8px 8px 0 0;
    margin: 0 20px;
    height: 0;
    opacity: 0;
    transition: 0;
    position: relative;
}
.chart .chart_bar:nth-of-type(2), .chart .chart_bar:nth-of-type(3), .chart .chart_bar:nth-of-type(5), .chart .chart_bar:nth-of-type(6), .chart .chart_bar:nth-of-type(7), .chart .chart_bar:nth-of-type(10){
    color: #fff;
}
.line_chart_con .bg_line{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.line_chart_con .bg_line span{
    width: 100%;
    height: 1px;
    background-color: #eee;
    display: block;
}

/* 차트 하단 상세 */
.chart_title{
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #202020;
    margin-top: 4px;
    margin-bottom: 24px;
}
.chart_name_area{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.chart_name{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 16px;
    opacity: 0;
    transition: 0.2s;
}
.chart_name:first-of-type{
    margin-left: 0;
}
.chart_name div{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 4px;
}
.chart_name span{
    display: block;
    max-width: 72px;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #3a3a3a;
    margin-top: 2px;
    word-break: break-all;
}

/* 도넛 차트 */
.donut_chart{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.donut_chart svg{
    width: 100%;
    height: 100%;
    overflow: visible;
    transition: 0.1s;
}
.donut_chart #donut{
    width: 100%;
    height: 100%;
    fill: transparent;
    stroke-width: 12.5px;
}
.donut_chart text{
    dominant-baseline: middle;
    text-anchor: start;
    font-size: 3.25px;
    font-weight: 400;
    fill: #3a3a3a;
    overflow: visible;
}
.donut_chart tspan{
    fill: #3a3a3a;
}
.donut_chart line{
    stroke: #d1d1d1;
    stroke-width: 0.2px;
}
.donut_chart_zoom_out{
    transform: scale(0.95);
}
.donut_chart_zoom_in{
    transform: scale(1);
}

/* react-color-palette : input 속성 */
.sketch-picker .flexbox-fix input{
    padding: 4px 10% 3px !important;
    border: none !important;
}

/* 민트색 글씨 */
.mint_text_color{
    color: #00e2e6 !important;
}

/* 이미지 업로드 버튼(label) + input(text) + input(file) */
.common_img_upload_area{
    display: flex;
    position: relative;
}
.common_img_upload_btn{
    width: 113px;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    margin-right: 12px;
    margin-bottom: 0px;
    color: #3A3A3A;
    display: grid;
    text-align: center;
    align-items: center;
    cursor: pointer;
    outline-color: aqua;
}
.common_img_upload_btn:hover {
    font-weight: 500;
}
.common_img_upload_area input:disabled{
    height: 40px;
    background-color: transparent !important;
    text-overflow: ellipsis;
    padding-right: 50px !important;
    padding: 14px 40px 14px 16px !important;
    border: 1px solid !important;
    border-color: #d1d1d1 !important;
    border-radius: 6px !important;
    transition: 0.2s !important;
    font-weight: 400;
    font-size: 14px;
    outline: none;
    pointer-events: none;
}
.common_img_upload_area input:disabled::placeholder{
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}
.common_img_upload_area svg{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

/* modal */
.modal_robomd {
    width: 100%;
    height: 100vh;
    padding: 40px;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.wrap_modal_robomd {
    width: 80%;
    min-height: 80%;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.05);
    position: fixed;
    z-index: 1000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 24px 20px;
    padding-bottom: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.btn_close_modal_robomd{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 12px;
    top: 12px;
}

/* 팝업 프레임 */
.common_popup_frame{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    position: absolute;
    overflow: auto;
    z-index: 10000;
    right: 0;
    top: 0;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}
.common_popup_frame_title_icon{
    display: flex;
    align-items: center;
    width: 100%;
}
.common_popup_frame .title_icon{
    transform: none;
}
.common_popup_frame_title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}
.common_popup_frame_title img{
    width: 15px;
    height: 14px;
    transform: translateY(-140%);
}
.common_popup_frame_title .back_btn{
    width: 0px;
}
.common_popup_frame_title .back_btn img{
    width: 20px;
    height: 20px;
    transform: translateY(-96%);
}
.common_popup_frame_div{
    max-width: 1200px;
    display: flex; /* none을 flex가 기본값이도록 변경*/
    width: 100%;
    padding: 32px 24px;
    border-radius: 10px;
    background-color: #fff;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.common_popup_frame_header{
    width: 100%;
    box-sizing: border-box;
}
.common_popup_frame_header h2{
    font-weight: 500;
}
.common_popup_frame_header .common_popup_frame_title{
    margin-bottom: 0px;
}

/* 태블릿 사이즈 미디어쿼리 */
@media screen and (max-width:1230px){
    .common_popup_frame{
        padding: 30px;
    }
}

/* 800 사이즈 미디어쿼리 */
@media screen and (max-width: 800px){
    .common_popup_frame{
        padding: 20px;
        position: fixed;
        /* z-index: 11; */
        width: 100%;
        height: 100%;
    }
    .common_popup_frame_title img{
        transform: translateY(-100%);
    }
    .common_popup_frame_title .back_btn img{
        transform: translateY(-70%);
    }
    .common_popup_frame_div{
        padding: 24px 16px;
        /* margin-top: 70px; */
    }
    .common_popup_frame_header{
        border-bottom: none;
    }
}
/* 팝업 프레임 V2 */
.common_popup_frame_v2_wrap * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.common_popup_frame_v2_wrap {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    background-color: #00000073;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.common_popup_frame_v2_frame{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    padding: 40px;
}
.common_popup_frame_v2_frame::-webkit-scrollbar{
    display: none;
}
.common_popup_frame_v2_content {
    max-width: 500px;
    width: 100%;
    padding: 36px 12px 12px 12px;
    border-radius: 10px;
    position: relative;
    background-color: #FFF;
}
.common_popup_frame_v2_close_btn {
    width: 24px;
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
}
@media screen and (max-width:800px){
    .common_popup_frame_v2_close_btn {
        width: 20px;
        position: absolute;
        right: 12px;
        top: 12px;
        cursor: pointer;
    }
    .common_popup_frame_v2_frame {
        padding: 40px 16px;
    }
}

/* 
    라디오 버튼
*/
.common_radio_button{
    display: flex;
    align-items: center;
}
.common_radio_button .radio{
    display: none !important;
}
.common_radio_button .radio_btn{
    width: 20px;
    height: 20px;
    border: 1px solid !important;
    border-radius: 50% !important;
    position: relative !important;
    cursor: pointer !important;
    transition: 0.2s !important;
}
.common_radio_button .radio_btn_inner{
    width: 9px;
    height: 9px;
    border-radius: 50% !important;
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    transition: 0.2s !important;
}
.common_radio_button .radio + label{
    margin-bottom: 0px;
}
.common_radio_button .radio + label + span{
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    margin-left: 6px;
    margin-right: 20px;
    padding-bottom: 2px;
}
.common_radio_button .radio + label .radio_btn{ /* Default */
    background-color: #fff !important;
    border-color: #ACACAC !important;
}
.common_radio_button .radio + label .radio_btn_inner{
    display: none;
    background-color: #d1d1d1 !important;
}
.common_radio_button .radio:checked + label .radio_btn{ /* Selected */
    background-color: #00e2e6 !important;
    border-color: #00e2e6 !important;
}
.common_radio_button .radio:checked + label .radio_btn_inner{
    display: block;
    background-color: #fff !important;
}
.common_radio_button .radio:disabled + label .radio_btn{ /* Disabled Unselected */
    background-color: #E3E3E3 !important;
    border-color: #ACACAC !important;
}
.common_radio_button .radio:disabled + label .radio_btn_inner{
    background-color: #eee !important;
}
.common_radio_button .radio:checked:disabled + label .radio_btn{ /* Disabled Selected */
    background-color: #e3e3e3 !important;
    border-color: #ACACAC !important;
}
.common_radio_button .radio:checked:disabled + label .radio_btn_inner{
    background-color: #fff !important;
}
@media (hover: hover) and (pointer: fine){
    .common_radio_button .radio + label:hover .radio_btn_inner{ /* Default Hover */
        display: block;
    }
    .common_radio_button .radio:checked + label:hover .radio_btn{ /* Selected Hover */
        background-color: #00e2e6 !important;
        border-color: #00e2e6 !important;
    }
    .common_radio_button .radio:checked + label:hover .radio_btn_inner{
        background-color: #fff !important;
    }
    .common_radio_button .radio:disabled + label:hover .radio_btn{ /* Disabled Hover */
        cursor: default !important;
    }
    .common_radio_button .radio:disabled + label:hover .radio_btn_inner{
        display: none;
        cursor: default !important;
    }
    .common_radio_button .radio:checked:disabled + label .radio_btn{ /* Disabled Selected Hover */
        background-color: #D1D1D1 !important;
        border-color: #e3e3e3 !important;
        cursor: default !important;
    }
    .common_radio_button .radio:checked:disabled + label .radio_btn_inner{
        display: block;
        background-color: #fff !important;
        cursor: default !important;
    }
}

/* 
    Check Box
    체크 박스
    (한 개 이상의 항목을 선택할 때 사용합니다. 항목이 많을 경우 모든 항목을 전체 선택할 수 있는 '전체 선택' 체크박스를 배치합니다.)
*/
.common_checkbox{
    display: flex;
    align-items: center;
    justify-content: center;
}
.common_checkbox .checkbox + label{
    margin-bottom: 0px;
    cursor: default;
    display: flex;
    justify-content: center;
}
.common_checkbox .checkbox + label + span{
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    margin-left: 6px;
    margin-right: 20px;
    padding-bottom: 2px;
}
.common_checkbox .checkbox + label svg{ /* Default */
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.common_checkbox .checkbox + label .checkbox_default{
    display: block;
}
.common_checkbox .checkbox + label .checkbox_selected{
    display: none;
}
.common_checkbox .checkbox + label .checkbox_default .check_icn {
    display: none;
}
@media (hover: hover) and (pointer: fine) {
    .common_checkbox .checkbox + label .checkbox_default:hover .check_icn {
        display: block;
    }
}
.common_checkbox .checkbox + label .checkbox_indeterminate, .checkbox + label .checkbox_indeterminate_disabled{
    display: none;
}
.common_checkbox .checkbox:checked + label .checkbox_default{ /* Checked */
    display: none;
}
.common_checkbox .checkbox:checked + label .checkbox_selected{
    display: block;
}
.common_checkbox .checkbox:disabled + label svg{ /* Disabled */
    cursor: default;
}
.common_checkbox .checkbox.indeterminate + label .checkbox_default, .checkbox.indeterminate + label .checkbox_selected{
    display: none;
}
.common_checkbox .checkbox.indeterminate + label .checkbox_indeterminate{ /* Indeterminate */
    display: block;
}
.common_checkbox .checkbox.indeterminate:disabled + label .checkbox_indeterminate{
    display: none;
}
.common_checkbox .checkbox.indeterminate:disabled + label .checkbox_indeterminate_disabled{
    display: block;
}

/* input - 넘버 / 텍스트 / 비밀번호 */
.common_text_input{
    position: relative;
}
.common_text_input input[type=text], 
.common_text_input input[type=number], 
.common_text_input input[type=password] {
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    color: #3A3A3A;
    border: 1px solid #D1D1D1;
    border-radius: 6px;
    padding: 12px 16px;
    transition: 0.2s;
    font-family: 'Noto Sans KR';
}
.common_text_input input[type=text]::placeholder, 
.common_text_input input[type=number]::placeholder, 
.common_text_input input[type=password]::placeholder {
    font-size: inherit;
    font-weight: 400;
    color: #878B90;
}
.common_text_input input[type=text]:focus::placeholder, 
.common_text_input input[type=number]:focus::placeholder, 
.common_text_input input[type=password]:focus::placeholder {
    color: transparent;
}
.common_text_input input[type=text]:disabled, 
.common_text_input input[type=number]:disabled, 
.common_text_input input[type=password]:disabled {
    outline: none;
    border-color: #D1D1D1;
}
.common_text_input input[type=text]:focus, 
.common_text_input input[type=number]:focus, 
.common_text_input input[type=password]:focus {
    outline: none;
    box-shadow: none;
    border-color: #00E2E6;
}
.common_text_input input[type=text].error,
.common_text_input input[type=number].error,
.common_text_input input[type=password].error {
    outline: none;
    box-shadow: none;
    border-color: #FF085E;
    padding-right: 50px !important;
}
.common_text_input input[type=text].success,
.common_text_input input[type=number].success,
.common_text_input input[type=password].success {
    outline: none;
    box-shadow: none;
    border-color: #00E677;
    padding-right: 50px !important;
}
.common_text_input input.error {
    background: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' fill='white'/%3E%3Cpath d='M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1229 7.84575 17.2662 5.78033 15.743 4.25704C14.2197 2.73376 12.1543 1.87707 10 1.875ZM9.375 6.25C9.375 6.08424 9.44085 5.92527 9.55806 5.80806C9.67527 5.69085 9.83424 5.625 10 5.625C10.1658 5.625 10.3247 5.69085 10.4419 5.80806C10.5592 5.92527 10.625 6.08424 10.625 6.25V10.625C10.625 10.7908 10.5592 10.9497 10.4419 11.0669C10.3247 11.1842 10.1658 11.25 10 11.25C9.83424 11.25 9.67527 11.1842 9.55806 11.0669C9.44085 10.9497 9.375 10.7908 9.375 10.625V6.25ZM10 14.375C9.81458 14.375 9.63333 14.32 9.47916 14.217C9.32499 14.114 9.20482 13.9676 9.13387 13.7963C9.06291 13.625 9.04434 13.4365 9.08052 13.2546C9.11669 13.0727 9.20598 12.9057 9.33709 12.7746C9.4682 12.6435 9.63525 12.5542 9.81711 12.518C9.99896 12.4818 10.1875 12.5004 10.3588 12.5714C10.5301 12.6423 10.6765 12.7625 10.7795 12.9167C10.8825 13.0708 10.9375 13.2521 10.9375 13.4375C10.9375 13.6861 10.8387 13.9246 10.6629 14.1004C10.4871 14.2762 10.2486 14.375 10 14.375Z' fill='%23FF085E'/%3E%3C/svg%3E") no-repeat;
    background-position: calc(100% - 15px) 50%;
    background-size: 20px 20px;
}
.common_text_input input.success {
    background: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' fill='white'/%3E%3Cpath d='M8.1251 15C7.95944 14.9977 7.80061 14.9336 7.67979 14.8203L3.30479 10.4453C3.20512 10.3239 3.15419 10.1697 3.1619 10.0128C3.1696 9.85587 3.2354 9.70744 3.34648 9.59636C3.45756 9.48527 3.606 9.41948 3.7629 9.41177C3.91981 9.40407 4.07398 9.455 4.19541 9.55466L8.1251 13.4922L16.4298 5.17966C16.5512 5.08 16.7054 5.02907 16.8623 5.03677C17.0192 5.04448 17.1676 5.11027 17.2787 5.22136C17.3898 5.33244 17.4556 5.48087 17.4633 5.63778C17.471 5.79469 17.4201 5.94885 17.3204 6.07029L8.57041 14.8203C8.44958 14.9336 8.29075 14.9977 8.1251 15Z' fill='%2300E677'/%3E%3C/svg%3E%0A") no-repeat;
    background-position: calc(100% - 15px) 50%;
    background-size: 20px 20px;
}
.common_text_input input ~ span {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.25;
    margin-left: 12px;
    color: #878B90;
}
.common_text_input input.success ~ span {
    margin-top: 4px;
}
.common_text_input input.error ~ span {
    margin-top: 4px;
    color: #FF085E;
}
@media (hover: hover) and (pointer: fine) {
    .common_text_input input[type=text]:hover, 
    .common_text_input input[type=number]:hover, 
    .common_text_input input[type=password]:hover {
        outline: none;
        border-color: #515151;
    }
    .common_text_input input[type=text]:disabled:hover, 
    .common_text_input input[type=number]:disabled:hover, 
    .common_text_input input[type=password]:disabled:hover {
        border-color: #D1D1D1;
    }
    .common_text_input input[type=text]:focus:hover, 
    .common_text_input input[type=number]:focus:hover, 
    .common_text_input input[type=password]:focus:hover {
        border-color: #00E2E6;
    }
    .common_text_input input[type=text].error:hover,
    .common_text_input input[type=number].error:hover,
    .common_text_input input[type=password].error:hover {
        border-color: #FF085E;
    }
    .common_text_input input[type=text].success:hover,
    .common_text_input input[type=number].success:hover,
    .common_text_input input[type=password].success:hover {
        outline: none;
        box-shadow: none;
        border-color: #00E677;
    }
}
.common_text_input .visible_chk {
    height: 20px;
    position: absolute;
    right: 12px;
    top: 0;
    transform: translateY(50%);
}
.common_text_input .visible_chk input + label {
    height: 100%;
    margin-bottom: 0;
    cursor: pointer;
}
.common_text_input .visible_chk input + label img {
    height: 100%;
}
.common_text_input .visible_chk input + label img:nth-of-type(1) { display: block; }
.common_text_input .visible_chk input + label img:nth-of-type(2) { display: none; }
.common_text_input .visible_chk input:checked + label img:nth-of-type(1) { display: none; }
.common_text_input .visible_chk input:checked + label img:nth-of-type(2) { display: block; }

/* 버튼 */
/* 버튼 오버 효과 - 민트 */
.common_button.hover_mint{
    border-radius: 100px !important;
    overflow: hidden !important;
    box-sizing: border-box !important;
    position: relative;
    z-index: 0 !important;
    transition: 0.2s transform ease-in-out !important;
    background-color: #00e2e6 !important;
    color: #202020 !important;
    line-height: 0;
}
.common_button.hover_mint::after{
    background-color: #00D3E0 !important;
    border-radius: 100px !important;
    content: '' !important;
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    z-index: -1 !important;
    left: 0 !important;
    top: 0 !important;
    transform: translate(-100%,0) rotate(10deg) !important;
    transform-origin: top left !important;
    transition: 0.2s transform ease-in-out !important;
}
.common_button.hover_mint.disabled{
    background-color: #DFFAFA !important;
    color: #B7BDC3 !important;
    cursor: default;
}
@media (hover: hover) and (pointer: fine){
    .common_button.hover_mint:hover{
        /* transform: translateZ(0) !important;
        backface-visibility: hidden !important;
        will-change: transform !important; */
        border-color: transparent !important;
    }
    .common_button.hover_mint:hover::after{
        transform: translate(0,0) !important;
    }
    .common_button.hover_mint.disabled:hover::after{
        transform: translate(-100%,0) !important;
    }
}

/* 마우스오버 효과 - 민트 테두리 버튼 */
.common_button.hover_mint_b{
    border-radius: 100px !important;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    transition: .2s;
    transform: ease-in-out;
    will-change: transform;
    z-index: 0;
    border: 1px solid #00e2e6;
    color: #3a3a3a;
}
.common_button.hover_mint_b::after{
    background-color: #00e2e6;
    border-radius: 20px;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%,0) rotate(10deg);
    transform-origin: top left;
    transition: .2s transform ease-out;
    will-change: transform;
    z-index: -1;
}
@media (hover: hover) and (pointer: fine){
    .common_button.hover_mint_b:hover{
        backface-visibility: hidden;
        will-change: transform;
        border-color: transparent !important;
    }
    .common_button.hover_mint_b:hover::after{
        transform: translate(0,0);
    }
}

/* 버튼 오버 효과 - 화이트 */
.common_button.hover_white{
    border-radius: 100px !important;
    overflow: hidden !important;
    box-sizing: border-box !important;
    position: relative;
    z-index: 0 !important;
    transition: 0.2s transform ease-in-out !important;
    background: #ffffff;
    border : 1px solid #ACACAC;
    word-break: break-all !important;
    line-height: 0;
}
.common_button.hover_white::after{
    background-color: #e3e3e3 !important;
    border-radius: 100px !important;
    content: '' !important;
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    z-index: -1 !important;
    left: 0 !important;
    top: 0 !important;
    transform: translate(-100%,0) rotate(10deg) !important;
    transform-origin: top left !important;
    transition: 0.2s transform ease-in-out !important;
}
.common_button.hover_white.disabled{
    background-color: #f7f7f7 !important;
    border-color: #eee !important;
    color: #B7BDC3 !important;
    cursor: default !important;
}
@media (hover: hover) and (pointer: fine){
    .common_button.hover_white:hover{
        /* transform: translateZ(0) !important;
        backface-visibility: hidden !important;
        will-change: transform !important; */
        border-color: #acacac !important;
    }
    .common_button.hover_white:hover::after{
        transform: translate(0,0) !important;
    }
    .common_button.hover_white.disabled:hover{
        border-color: #eee !important;
    }
    .common_button.hover_white.disabled:hover::after{
        transform: translate(-100%,0) !important;
    }
}

/* 버튼 - 투명배경 */
.common_button.hover_transparent{
    border-radius: 100px;
    overflow: hidden;
    transition: 0.2s transform ease-in-out;;
    color: #3a3a3a;
    line-height: 0;
}
.common_button.hover_transparent.disabled{
   color: #b7bdc3; 
}
.common_button.hover_transparent.disabled{
    color: #B7BDC3;
    cursor: default;
}
@media (hover: hover) and (pointer: fine){
    .common_button.hover_transparent:hover{
        font-weight: 500;
    }
    .common_button.hover_transparent.disabled:hover{
        font-weight: 400;
    }
}

/* 버튼 - 검은색 */
.common_button.hover_dark{
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    transition: 0.2s transform ease-in-out;
    background-color: #333;
    color: #fff;
    line-height: 0;
}
.common_button.hover_dark::after{
    background-color: #000;
    border-radius: 100px;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    transform: translate(-100%,0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-in-out;
}
.common_button.hover_dark.disabled{
    background-color: #f7f7f7;
    color: #B7BDC3;
    cursor: default;
}
@media (hover: hover) and (pointer: fine){
    .common_button.hover_dark:hover{
        transform: translateZ(0);
        backface-visibility: hidden;
        will-change: transform;
        border-color: transparent;
    }
    .common_button.hover_dark:hover::after{
        transform: translate(0,0);
    }
    .common_button.hover_dark.disabled:hover::after{
        transform: translate(-100%,0);
    }
}

/* 버튼 - 파란색 */
.common_button.hover_blue{
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    transition: 0.2s transform ease-in-out;
    background-color: #0090ff;
    color: #fff;
    line-height: 0;
}
.common_button.hover_blue::after{
    background-color: #006fe6;
    border-radius: 100px;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    transform: translate(-100%,0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-in-out;
}
.common_button.hover_blue.disabled{
    background-color: #e2f1ff;
    color: #B7BDC3;
    cursor: default;
}
@media (hover: hover) and (pointer: fine){
    .common_button.hover_blue:hover{
        transform: translateZ(0);
        backface-visibility: hidden;
        will-change: transform;
        border-color: transparent;
    }
    .common_button.hover_blue:hover::after{
        transform: translate(0,0);
    }
    .common_button.hover_blue.disabled:hover::after{
        transform: translate(-100%,0);
    }
}

/* 버튼 - 그레이 */
.common_button.hover_grey{
    background-color: #e3e3e3;
    color: #3a3a3a;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    transition: 0.2s transform ease-in-out;
    line-height: 0;
}
.common_button.hover_grey::after{
    background-color: #acacac;
    border-radius: 100px;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    transform: translate(-100%,0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-in-out;
}
.common_button.hover_grey.disabled{
    background-color: #f7f7f7;
    color: #B7BDC3;
    cursor: default;
}
@media (hover: hover) and (pointer: fine){
    .common_button.hover_grey:hover{
        transform: translateZ(0);
        backface-visibility: hidden;
        will-change: transform;
        border-color: #acacac;
    }
    .common_button.hover_grey:hover::after{
        transform: translate(0,0);
    }
    .common_button.hover_grey.disabled:hover::after{
        transform: translate(-100%,0);
    }
}

/* 아이콘 */
.common_icon{
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.common_icon .hover_box{
    display: none;
    position: absolute;
    top:-22px;
    width: 52px;
    height:auto;
    padding:4px 2px;
    line-height: 1;
    background: white;
    font-size: 12px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.common_icon:hover .hover_box{
    display: block;
}

/* 정보 */
.alert_info{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px !important;
}
.alert_info svg{
    width: 20px;
    height: 20px;
    margin-right: 4px;
}
.alert_info span{
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #63666a;
}
.alert_warning{ /* 경고 */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px !important;
}
.alert_warning svg{
    width: 20px;
    height: 20px;
    margin-right: 4px;
}
.alert_warning span{
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #ff085e;
}

/* 
    Tooltip
    툴팁
    (특정 요소에 Hover 됐을 때 상황에 맞는 도움말이나 정보를 표시합니다. 새로운 기능에 대한 안내가 필요한 경우에만 화면을 딤드하여 표시합니다. 상황에 따라 툴팁이 제공되는 위치는 변경될 수 있습니다.)
*/
.common_tooltip{
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.common_tooltip svg{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s;
}
.common_tooltip .non_hover{
    opacity: 1;
}
.common_tooltip .hover{
    opacity: 0;
}
.common_tooltip .memo_area{
    display: none;
    position: relative;
    opacity: 0;
    transition: 0.2s;
}
.common_tooltip span{
    width: 240px;
    border-radius: 10px;
    padding: 16px 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    position: absolute;
    left: 50%;
    top: 36px;
    transform: translateX(-50%);
    background-color: #515151;
    color: #fff;
}
.common_tooltip .arrow{
    width: 12px;
    height: 12px;
    background-color: #515151;
    position: absolute;
    left: 50%;
    top: 30px;
    transform: translateX(-50%) rotate(45deg);
}
@media (hover: hover) and (pointer: fine){
    .common_tooltip:hover .non_hover{
        opacity: 0;
    }
    .common_tooltip:hover .hover{
        opacity: 1;
    }
    .common_tooltip:hover .memo_area{
        opacity: 1;
        display: block;
    }
}