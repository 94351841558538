:global .ImageUploadTypePopup.common_popup_frame_v2_wrap{
    justify-content: center;
}
.popup_title{
    font-family: "Noto Sans KR";
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 8px;
}
.popup_content{
    font-family: "Noto Sans KR";
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 12px;
}
.img_type_area{
    display: flex;
    justify-content: center;
    margin-top: 32px;
}
.event_img_preview_area{
    margin: 0px 6px;
    cursor : pointer;
    width: 88px;
    height: 88px;
    position: relative;
}
.non_preview_img_area .default_img{
    background-color: #F0F0F0 !important;
}
.event_img_preview_area .use_img, .event_img_preview_area .default_img, .event_img_preview_area .disabled_img{
    width: 100%;
    height : 100%;
    object-fit : cover;
    position: absolute;
    border-radius: 8px;
    background-color: #F0F0F0;
    display: flex;
    flex-direction: column;
}
.event_img_preview_area .default_img img{
    width: 24px;
    height: 24px;
    display: block;
    margin: 20px 32px 0px 32px;
}
.event_img_preview_area .default_img div{
    font-family: "Noto Sans KR";
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;

}
.event_img_preview_area .default_img .ai_badge{
    position: absolute;
    margin: 4px !important;
    width: 13px;
    height: 13px;
}
.event_img_preview_area .disabled_img{
    background-color: #fff;
    text-align: center;
    font-size: 14px;
    display: flex;
    justify-content: center;
}
.event_img_preview_area:hover > .default_img, .event_img_preview_area:hover > label > .default_img{
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
}
.button_area{
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 12px;
}
.button_area button{
    margin: 0px 4px;
}

@media (max-width:800px) {
    :global .ImageUploadTypePopup .common_popup_frame_v2_content{
        max-width: 328px !important;
    }

    .img_type_area{
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 24px;
    }
    .event_img_preview_area{
        margin: 8px;
        width: 108px;
        height: 108px;
    }
    .event_img_preview_area .default_img img{
        margin: 31px auto 0px auto;
    }
    .event_img_preview_area .default_img div{
        margin-top: 8px;
    }
    .event_img_preview_area .default_img .ai_badge{
        width: 16px;
        height: 16px;
    }
    .button_area{
        margin-top: 32px;
    }

}