.header {
    position: fixed;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;

    width: 100%;
    height: 72px;
    
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);

    display: flex;
    align-items: center;
    margin-left: 15px;
}