.products_tabs {
    display: flex;
}
.product_tabs select {
    margin-left: 1.25rem;
}

.products_wrap .react-datepicker-wrapper {
    margin-bottom: 0 !important;
}

/* 탭 끝 */
.products_wrap .products_container {
    position: relative;
    margin-top: 1.25rem;
    padding: 3.125rem 3.125rem 3.75rem 3.125rem;
    background: #fff;
    border-radius: 0.625rem;
}
.products_wrap .products {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 0;
}
.products_wrap .products > li {
    max-width: 12.25rem;
    width: 100%;
    margin-right: 1.875rem;
    margin-bottom: 3.75rem;
}
.products_wrap .products > li:nth-child(5n) {
    margin-right: 0;
}
.products_wrap .products .cont_top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8125rem;
}
.products_wrap .products .cont_top h3 {
    font-family: "Noto Sans 700";
    font-size: 1.25rem;
    color: #333;
}
.products_wrap .products .cont_top .icon {
    display: flex;
    border: 1px;
}
.products_wrap .products .cont_top .icon .img_area {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4375rem 0.3125rem;
}
.products_wrap .products .cont_top .icon_gray .img_area {
    padding: 0.75rem 0.4375rem;
}
.products_wrap .products .cont_top .icon .img_area img{
    width: 100%;
}
.products_wrap .products .cont_top .icon .img_area,
.products_wrap .products .cont_top .icon .number {
    width: 1.6875rem;
    height: 1.6875rem;
    border-radius: 0.3125rem;
}
.products_wrap .products .cont_top .icon_new .img_area {
    background: #dbdcdc;
}
.products_wrap .products .cont_top .icon_green .img_area,
.products_wrap .products .cont_top .icon_green .number {
    background: #10cfc9;
}
.products_wrap .products .cont_top .icon_gray .img_area,
.products_wrap .products .cont_top .icon_gray .number {
    background: #f5f5f7;
}
.products_wrap .products .cont_top .icon_pink .img_area,
.products_wrap .products .cont_top .icon_pink .number {
    background: #ffaec2;
}
.products_wrap .products .cont_top .icon .number {
    margin-left: 0.3175rem;
    text-align: center;
}
.products_wrap .products .cont_top .icon .number p {
    line-height: 1.6875rem;
    font-family: "Noto Sans 700";
    font-size: 0.9375rem;
    margin: 0;
    color: #fff;
}
.products_wrap .products .cont_top .icon_gray .number p {
    color: #333;
}
.products_wrap .products .cont_md {
    overflow: hidden;
    display: flex;
    max-width: 325px;
    max-height: 325px;
    width: auto;
    height: auto;
    border-radius: 0.3125rem;
}
.products_wrap .products .cont_bt {
    display: flex;
    flex-direction: column;
}
.products_wrap .products .cont_bt p {
    margin-top: 1.875rem;
    margin-bottom: 0.625rem;
    font-size: 0.9375rem;
    color: #333;
}
.products_wrap .products .cont_bt span {
    font-family: "Noto Sans 700";
    font-size: 0.9375rem;
    color: #333;
}

/* 컨텐츠 끝 */

/* 목록 버튼 */
.pagination_wrap .pagination_container {
    display: flex;
    align-items: center;
    margin-top: 3.125rem;
    padding: 0 0.625rem;
}
.pagination_wrap .pagination_num {
    display: flex;
    align-items: center;
}
.pagination_wrap .pagination_num li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    margin-left: 0.5rem;
    background: #fff;
    border: 1px solid #dbdde4;
    box-sizing: border-box;
    border-radius: 0.25rem;
}
.pagination_wrap .pagination_num li:first-child {
    margin-left: 0;
}
.pagination_wrap .pagination_num li.active {
    background: #10cfc9;
    border: none;
}
.pagination_wrap .pagination_num li.active a{
    color: #fff;
}
.pagination_wrap .pagination_num li a {
    display: block;
    width: 100%;
    text-align: center;
    font-family: "Noto Sans 700";
    font-size: 0.75rem;
    color: #333;
}
.pagination_wrap .pagination_num li p {
    display: inline-block;
    width: 100%;
    height: 100%;
}
.pagination_wrap .pagination_num li img {
    width: 50%;
}
.pagination_wrap button {
    width: 0.5rem;
    height: 0.75rem;
    border: none;
}
.pagination_wrap .pagination_prev {
    margin-right: 1.875rem;
    background: url(../img/icon/ico_first_left_arrow.png);
}
.pagination_wrap .pagination_next {
    margin-left: 1.875rem;
    background: url(../img/icon/ico_first_right_arrow.png);
}


/* 고탑 */
.go-top {
    z-index: 500;
    position: fixed;
    bottom: 6.25rem;
    right: 6.25rem;
    display: block;
}
.go-top button {
    width: 4.375rem;
    height: 4.375rem;
    border-radius: 100%;
    background: #fff;
    border: none;
    box-shadow: 0px 5px 20px 0px #0000001A;
    background: url(../img/icon/go_top.png) no-repeat #fff;
    background-position: center;
}


.highcharts-legend-item {
    display: none;
}
.highcharts-exporting-group {
    display: none;
}
.highcharts-credits {
    display: none;
}


.products li .conts {
    position: absolute;
    top: 0.84375rem;
    left: 9.25rem;
    display: none;
    max-width: 27.8125rem;
    width: 100%;
    padding: 50px;
    background: #fff;
    box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.1);
}
.conts .chart_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 1.875rem;
}
.conts .chart_title button {
    width: 1.25rem;
    height: 1.25rem;
    border: none;
    background: url(../img/icon/ico_close.png)no-repeat #fff;
    background-size: 100%;
}

.products li .conts p{
    font-family: "Noto Sans 700";
    font-size: 1.25rem;
    font-weight: 500;
    color: #333;
}
.products li .conts .chart{
    width: 100%;
    height: 100%;
}
.products li .conts .highcharts-point {
    opacity: 0;
}
.products li .conts .highcharts-point:hover {
    opacity: 1 !important;
}